import SessionStorageService from "../auth/SessionStorageService";
import SessionTimeService from "../auth/SessionTimeService";
import { isArray, log } from "util";
import api from "../config/api";

class UserServices {


	static workmode() {
    let user = JSON.parse(sessionStorage.getItem("react-user"));
 
    return user.value.headquarters.work_mode_id
  }


  static hasUserRole(roles) {
    const userData = SessionStorageService.get("react-user");
    if (userData == null) return false;
    let rolesUser = userData.value.roles;
    if (typeof roles === "object" && roles instanceof Array) {
      let flag = false;
      roles.forEach(item => {
        rolesUser.forEach(element => {
          if (item === element) {
            flag = true;
          }
        });
      });
      return flag;
    } else if (typeof roles === "string") {
      return rolesUser.includes(roles);
    }
    return false;
  }

  static hasUserRoleSuperAdmin() {
    const userData = SessionStorageService.get("react-user");
    if (userData == null) return false;
    let headquatersId = userData.value.headquarters_id

	return this.hasUserRole("admin") && headquatersId == 1;
  }
  static headersUpload() {
    let headers = {
      "X-Requested-With": "XMLHttpRequest"
    };
    const userData = SessionStorageService.get("react-user");
    if (!!userData && typeof userData.value.api_token === "string") {
      headers["Authorization"] = userData.value["api_token"];
    }
    SessionTimeService.renewSession();
    return headers;
  }

  static createHeaders() {
    let headers = {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest"
    };
    const authorization = this.getAuthorization();
    if (!!authorization) {
      headers["Authorization"] = authorization;
    }
    return headers;
  }

  static headersDowload() {
    let headers = {
      "Content-Type": "application/json"
    };
    const authorization = this.getAuthorization();
    if (!!authorization) {
      headers["Authorization"] = authorization;
    }
    return headers;
  }

  static getAuthorization() {
    let authorization = null;
    const userData = SessionStorageService.get("react-user");
    if (!!userData && typeof userData.value.api_token === "string") {
      authorization = userData.value["api_token"];
    }
    SessionTimeService.renewSession();
    return authorization;
  }

  static createInit(method) {
    const myInit = {
      method: method,
      headers: this.createHeaders(),
      responseType: "json"
    };
    return myInit;
  }
  static createCustomizedHeader(method, body) {
    const myInit = {
      method: method,
      headers: this.createHeaders(),
      responseType: "json",
      body: JSON.stringify(body)
    };
    return myInit;
  }
  static getAllUsers() {
    const url = `${api}/users`;
    const res = [];
    fetch(url, this.createInit("GET"))
      .then(response => response.json())
      .then(data => {
        res = data;
      });
    return res;
  }
  static getRoleUser() {
    const userData = SessionStorageService.get("react-user");
    if (!!userData && isArray(userData.value.roles)) {
      return userData.value.roles[0];
    }
    return null;
  }

  static isUser(userid) {
    const user = this.getUser();
    return user.id === userid;
  }

  static getUser() {
    const userData = SessionStorageService.get("react-user");
    if (!!userData) {
      return userData.value;
    }
    return null;
  }
  static getHeadquaters() {
    const userData = SessionStorageService.get("react-user");
    if (!!userData) {
      return userData.value.headquaters;
    }
    return {};
  }
}

export default UserServices;
