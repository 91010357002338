import React, { useState, useEffect } from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import useHttp from "../hook/http";

const SelectEstadoCivil = (props) => {
  const { value = null, onChange } = props;
  const [loadDepartaments, departaments] = useHttp("departamentos", []);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [loadDepartaments]);

  return loading ? (
    "Cargando..."
  ) : (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>Departamento</InputLabel>
      <Select value={value} onChange={onChange} label="Departamento">
        {departaments.map((ev) => (
          <MenuItem value={ev}>{ev}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectEstadoCivil;
