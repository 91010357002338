import React, { useState } from "react";
import Page from "../../components/Page/Page";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import { Grid, Box } from "@material-ui/core";

import StepButton from "@material-ui/core/StepButton";
import StepContent from "@material-ui/core/StepContent";
import Padre from "./Padre/Padre";
import Madre from "./Madre/Madre";
import Apoderado from "./Apoderado/Apoderado";
import Postulante from "./Postulante/Postulante";
import ResumenPostulante from "./Resumen/ResumenPostulante";
//Padre
import DatosAdicionales from "./Padre/DatosAdicionales";
import DatosGenerales from "./Padre/DatosGenerales";
import Direccion from "./Padre/Direccion";
import DatosEmpleo from "./Padre/DatosEmpleo";

//Madre
import MadreDatosGenerales from "./Madre/DatosGenerales";
import MadreDireccion from "./Madre/Direccion";
import MadreDatosEmpleo from "./Madre/DatosEmpleo";
import MadreDatosAdicionales from "./Madre/DatosAdicionales";
//Apoderado

import ApoderadoDatosGenerales from "./Apoderado/DatosGenerales";
import ApoderadoDireccion from "./Apoderado/Direccion";
import ApoderadoDatosEmpleo from "./Apoderado/DatosEmpleo";
import ApoderadoDatosAdicionales from "./Apoderado/DatosAdicionales";

//postulante

import PostulanteDatosGenerales from "./Postulante/DatosGenerales";
import PostulanteDocumentos from "./Postulante/Documentos";
import PostulanteInformacionAdicional from "./Postulante/InformacionAdicional";

//Resumen
import Resumen from "./Resumen/Resumen";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    minHeight: "100vh",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paginate: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
  },
}));

function getSteps() {
  return ["Padre", "Madre", "Apoderado", "Postulante", "Enviar Solicitud"];
}
const Agenda = () => {
  const classes = useStyles();
  const [indexStep, setindexStep] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setindexStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setindexStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNextActive = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setindexStep(0);
  };
  const handleBackActive = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setindexStep(0);
  };

  function getStepContentPaper(step, stepnested) {
    switch (step) {
      case 0:
        return getStepContentPaperPadre(stepnested);
      case 1:
        return getStepContentPaperMadre(stepnested);
      case 2:
        return getStepContentPaperApoderado(stepnested);
      case 3:
        return getStepContentPaperPostulante(stepnested);
      case 4:
        return getStepContentPaperResumen(stepnested);
      default:
        return "NO CONTROLLED INFORMATION";
    }
  }

  function getStepContentPaperPadre(step) {
    switch (step) {
      case 0:
        return (
          <DatosGenerales
            handleNext={handleNext}
            handleNextItem={handleNextActive}
          />
        );
      case 1:
        return <Direccion handleBack={handleBack} handleNext={handleNext} />;
      case 2:
        return <DatosEmpleo handleBack={handleBack} handleNext={handleNext} />;
      case 3:
        return (
          <DatosAdicionales
            handleBack={handleBack}
            handleNext={handleNextActive}
          />
        );
      default:
        return "NO CONTROLLED INFORMATION";
    }
  }
  function getStepContentPaperMadre(step) {
    switch (step) {
      case 0:
        return (
          <MadreDatosGenerales
            handleBackItem={handleBackActive}
            handleNext={handleNext}
            handleNextItem={handleNextActive}
          />
        );
      case 1:
        return (
          <MadreDireccion handleBack={handleBack} handleNext={handleNext} />
        );
      case 2:
        return (
          <MadreDatosEmpleo handleBack={handleBack} handleNext={handleNext} />
        );
      case 3:
        return (
          <MadreDatosAdicionales
            handleBack={handleBack}
            handleNext={handleNextActive}
          />
        );
    }
  }
  function getStepContentPaperApoderado(step) {
    switch (step) {
      case 0:
        return (
          <ApoderadoDatosGenerales
            handleBackItem={handleBackActive}
            handleNext={handleNext}
            handleNextItem={handleNextActive}
          />
        );
      case 1:
        return (
          <ApoderadoDireccion handleBack={handleBack} handleNext={handleNext} />
        );
      case 2:
        return (
          <ApoderadoDatosEmpleo
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );
      case 3:
        return (
          <ApoderadoDatosAdicionales
            handleBack={handleBack}
            handleNext={handleNextActive}
          />
        );
    }
  }
  function getStepContentPaperPostulante(step) {
    switch (step) {
      case 0:
        return (
          <PostulanteDatosGenerales
            handleBackItem={handleBackActive}
            handleNext={handleNext}
            handleNextItem={handleNextActive}
          />
        );
      case 1:
        return (
          <PostulanteDocumentos
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );
      case 2:
        return (
          <PostulanteInformacionAdicional
            handleBack={handleBack}
            handleNextItem={handleNextActive}
          />
        );
    }
  }

  function getStepContentPaperResumen(step) {
    switch (step) {
      case 0:
        return (
          <Resumen handleNext={handleNext} handleNextItem={handleNextActive} />
        );
    }
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <Padre indexStep={indexStep} />;
      case 1:
        return <Madre indexStep={indexStep} />;
      case 2:
        return <Apoderado indexStep={indexStep} />;
      case 3:
        return <Postulante indexStep={indexStep} />;
      case 4:
        return <ResumenPostulante indexStep={indexStep} />;
      default:
        return "Unknown step";
    }
  }

  function buttonStep(index) {
    if (index !== 5) {
      setActiveStep(index);
      setindexStep(0);
    }
  }

  return (
    <Page className={classes.root}>
      <Box pt={7}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Stepper orientation="vertical" nonLinear activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepButton
                    onClick={() => buttonStep(index)}
                    // completed={isStepComplete(index)}
                    // {...buttonProps}
                  >
                    <StepLabel>{label}</StepLabel>
                  </StepButton>
                  <StepContent>{getStepContent(index)}</StepContent>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={12} md={9}>
            <Card>
              <CardContent>
                {getStepContentPaper(activeStep, indexStep)}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default Agenda;
