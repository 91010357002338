import React, { useEffect } from "react";
import useForm from "react-hook-form";
import Button from "@material-ui/core/Button";
import { useState } from "react";
import infouser from "../../../config/user";
import axios from "axios";
import posthttps from "../../../hook/fetch/posthttps";
import api from "../../../config/api";
import SelectTypeDocument from "../../../utils/SelectTypeDocument";
import { Grid, Box, TextField, FormHelperText } from "@material-ui/core";
import Spinner from "../../Spinner/Spinner";
import { useParams } from "react-router-dom";

const Documentos = (props) => {
  const { idStudent } = useParams();
  const { handleSubmit, register, errors } = useForm();
  const [photo, setphoto] = useState(null);
  const [imageDocument, setimageDocument] = useState(null);
  const [numberDocument, setnumberDocument] = useState("");
  const [typeDocument, settypeDocument] = useState("DNI");
  const [downloadPhoto, setdownloadPhoto] = useState(null);
  const [downloadDocument, setdownloadDocument] = useState(null);

  const [loading, setloading] = useState(true);

  async function infoUser() {
    let obj = {
      id: idStudent,
    };
    let res = await posthttps("infouser", obj);
    if (res.data.postulant.documentos !== undefined) {
      let result = res.data.postulant.documentos;
      settypeDocument(result.tipoDocumento);
      setnumberDocument(result.nroDocumento);
      setdownloadDocument(result.photoDocumento);
      setdownloadPhoto(result.photoPerfil);
    }
    setloading(false);
  }

  useEffect(() => {
    infoUser();
  }, []);

  function saveData(values) {
    sendData(values);
  }

  async function sendData(values) {
    setloading(true);
    let formData = new FormData();
    formData.append("n_doc", values.numberDocument);
    formData.append("t_doc", typeDocument);
    if (photo) {
      formData.append("imgPerfil", photo);
    }
    if (imageDocument) {
      formData.append("imgDocument", imageDocument);
    }
    formData.append("relationship", "postulant");
    formData.append("dato", "documentos");

    await axios.post(
      `${api("form")}/documents-applicant/${idStudent}`,
      formData
    );
    props.handleNext();
    setloading(false);
  }

  return (
    <Box p={2}>
      {loading ? (
        <Spinner />
      ) : (
        <form onSubmit={handleSubmit(saveData)} autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <SelectTypeDocument
                value={typeDocument}
                onChange={(e) => settypeDocument(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Nro documento"
                variant="outlined"
                fullWidth
                defaultValue={numberDocument}
                inputProps={{ minLength: 7, maxLength: 15 }}
                name="numberDocument"
                inputRef={register({
                  required: "Número de documento obligatorio",
                  pattern: {
                    value: /^[0-9A-Z]{7,15}$/i,
                    message: "Número invalido",
                  },
                })}
              />
              <FormHelperText error>
                {errors.numberDocument && errors.numberDocument.message}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Adjunto documento de identidad"
                variant="outlined"
                type="file"
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setimageDocument(e.target.files[0])}
                inputProps={{ accept: "image/*, application/pdf" }}
              />
              <FormHelperText>
                SOLO ADJUNTAR DOCUMENTO. Adjuntar el documento personal del
                alumno/a: DNI,Carné de Extranjería o Carné Diplomático.
                <br /> El archivo debe ser una imagen o un archivo pdf
              </FormHelperText>
              <object
                data={
                  downloadDocument
                    ? downloadDocument
                    : "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2e/DNI_peruano.jpg/200px-DNI_peruano.jpg"
                }
                style={{ width: "100%", height: "25vh" }}
              >
                ARCHIVO ADJUNTO
              </object>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Foto de perfíl"
                variant="outlined"
                type="file"
                InputLabelProps={{ shrink: true }}
                fullWidth
                onChange={(e) => setphoto(e.target.files[0])}
                inputProps={{ accept: "image/*" }}
              />
              <FormHelperText>Adjuntar la foto del solicitante</FormHelperText>
              <img
                alt="Foto frontal"
                style={{ maxWidth: "100%", maxHeight: "25vh" }}
                src={
                  downloadPhoto
                    ? downloadPhoto
                    : "https://i1.wp.com/icon-library.com/images/no-profile-picture-icon/no-profile-picture-icon-15.jpg"
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={() => props.handleBack()}
                variant="contained"
                color="primary"
              >
                Atras
              </Button>{" "}
              <Button type="submit" variant="contained" color="secondary">
                Siguiente
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Box>
  );
};

export default Documentos;
