import React from "react";
import {
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  ListItemSecondaryAction,
} from "@material-ui/core";

const Item = ({ title, content, end = false, start = false }) => {
  return (
    <>
      {start && <Divider component="li" />}
      <ListItem divider={end}>
        <ListItemIcon />
        <ListItemText primary={title} />
        <ListItemSecondaryAction>
          <Typography variant="body2">{content}</Typography>
        </ListItemSecondaryAction>
      </ListItem>
      {end ? null : <Divider variant="inset" component="li" />}
    </>
  );
};

export default Item;
