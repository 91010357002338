import React, { useState, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import MainFeaturedPost from "./MainFeaturedPost";
import useForm from "react-hook-form";
import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import AuthenticationService from "../../auth/AuthenticationService";
import { notification } from "antd";
import Spinner from "../../views/Spinner/Spinner";
import posthttps from "../../hook/fetch/posthttps";
import { FormHelperText } from "@material-ui/core";

export default function Blog(props) {
  const [data, setdata] = useState();
  const [loading, setloading] = useState(true);
  const { handleSubmit, register, errors } = useForm();

  const [redirectToReferrer, setRedirectToReferrer] = useState(false);

  const onSubmit = (val) => {
    let values = {
      username: val.email,
      password: val.password,
    };
    setloading(true);
    AuthenticationService.login(values)
      .then((res) => {
        if (res.status === "200") {
          setRedirectToReferrer(true);
        } else {
          notification["error"]({
            message: "Login",
            description: "El usuario y/o la contraseña es incorrecta.",
          });
        }
      })
      .catch((error) => {
        notification["error"]({
          message: "Login",
          description: "El usuario y/o la contraseña es incorrecta.",
        });
      })
      .finally(() => {
        setloading(false);
      });
  };

  async function getCollege() {
    let obj = {
      url: props.match.params.id,
    };
    let res = await posthttps("school/url", obj, "classroom");
    setdata(res);
    setloading(false);
  }

  useEffect(() => {
    getCollege();
  }, []);

  const { from } = props.location.state || { from: { pathname: "/" } };
  if (redirectToReferrer) {
    return <Redirect to={from} />;
  }

  if (loading || data == null) {
    return <Spinner />;
  } else {
    return (
      <React.Fragment>
        <CssBaseline />
        <main>
          <MainFeaturedPost post={data} />
          <Container style={{ marginTop: "-25vh" }}>
            <Card>
              <CardContent>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <Typography variant="h3" component="h1">
                      Acceso a Padres del colegio {data.tradeName}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Si no tiene hijos en el Colegio {data.tradeName} deberá
                      crear un email de Registro y una Clave. Por favor anotar
                      esta información para poder ingresar sus datos y completar
                      la solicitud. La primera vez que postula, deberá hacer
                      clic en el botón Registrarse.
                    </Typography>
                    <br />
                    <Link to={`/${props.match.params.id}/register`}>
                      <Button variant="contained" color="primary">
                        Registrarse
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" color="textSecondary">
                      Si ya tienen un email y clave, ingresarlos y hacer clic en
                      el botón ENTRAR.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Container maxWidth="sm">
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        autoComplete="off"
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              label="Email"
                              name="email"
                              variant="outlined"
                              fullWidth
                              inputRef={register({
                                required: "Email Obligatorio",
                                pattern: {
                                  value:
                                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                  message: "Email invalido",
                                },
                              })}
                            />
                            <FormHelperText error>
                              {errors.email && errors.email.message}
                            </FormHelperText>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label="Contraseña"
                              type="password"
                              name="password"
                              variant="outlined"
                              fullWidth
                              inputRef={register({
                                required: "Campo Obligatorio",
                              })}
                            />
                            <FormHelperText error>
                              {errors.password && errors.password.message}
                            </FormHelperText>
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              type="submit"
                              color="primary"
                            >
                              Entrar
                            </Button>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="caption" color="textSecondary">
                              Si olvidó su email de registro y/o Clave, haga
                              clic <Link to="/recovery/account">Aqui</Link>
                            </Typography>
                          </Grid>
                        </Grid>
                      </form>
                    </Container>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Container>
        </main>
      </React.Fragment>
    );
  }
}
