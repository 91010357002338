import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardActionArea,
  Typography,
  Grid,
  Box,
  Avatar,
} from "@material-ui/core";
import posthttps from "../../hook/fetch/posthttps";
import { Alert } from "@material-ui/lab";
import { notification } from "antd";
import infouser from "../../config/user";
import { Link } from "react-router-dom";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Spinner from "../Spinner/Spinner";

const Inicio = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getChilds();
  }, []);

  const getChilds = async () => {
    setLoading(true);
    try {
      let res = await posthttps("mychilds", {
        email: infouser.getUser(),
      });
      setStudents(res);
    } catch (error) {
      notification["error"]({
        message: "Ocurrió un error al obtener la información",
      });
    } finally {
      setLoading(false);
    }
  };

  const addChild = async () => {
    setLoading(true);
    try {
      let { _id, postulant, __v, complete, state, ...rest } =
        students[students.length - 1];
      await posthttps("add-child", rest);
      getChilds();
      notification["success"]({
        message: "El hijo se agregó correctamente.",
      });
    } catch (error) {
      notification["error"]({
        message: "Ocurrió un error al agregar el nuevo hijo.",
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box p={3} pt={10} style={{ minHeight: "100vh" }}>
      {loading ? (
        <Spinner />
      ) : (
        <Grid container spacing={1}>
          {students.map((ev) => (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Card style={{ height: "100%" }} variant="outlined">
                <CardActionArea
                  component={Link}
                  to={{ pathname: `/admision/${ev._id}` }}
                >
                  <CardContent align="center">
                    <Typography
                      variant="h6"
                      style={{
                        color: ev.complete ? "#00bf33" : "#b5af00",
                      }}
                    >
                      {ev.complete
                        ? "Postulación completada"
                        : "Postulación pendiente"}
                    </Typography>
                    {ev.postulant ? (
                      <>
                        {ev.postulant.documentos && (
                          <>
                            {ev.postulant.documentos.photoPerfil && (
                              <Avatar
                                src={ev.postulant.documentos.photoPerfil}
                                style={{ height: 75, width: 75 }}
                              />
                            )}
                            <Typography align="center" variant="body1">
                              DNI: {ev.postulant.documentos.nroDocumento}
                            </Typography>
                          </>
                        )}
                        {ev.postulant.datosGenerales && (
                          <Typography align="center" variant="body1">
                            {ev.postulant.datosGenerales.firstName}{" "}
                            {ev.postulant.datosGenerales.secondName}{" "}
                            {ev.postulant.datosGenerales.surname}{" "}
                            {ev.postulant.datosGenerales.secondSurname}
                          </Typography>
                        )}
                        {ev.complete ? (
                          <Alert
                            severity={
                              ev.state === "ACEPTADO"
                                ? "success"
                                : ev.state === "PENDIENTE"
                                ? "info"
                                : "error"
                            }
                            style={{ justifyContent: "center" }}
                          >
                            {ev.state}
                          </Alert>
                        ) : (
                          <Alert
                            severity="warning"
                            style={{ justifyContent: "center" }}
                          >
                            COMPLETAR REGISTRO
                          </Alert>
                        )}
                      </>
                    ) : (
                      <Alert
                        severity="warning"
                        style={{ justifyContent: "center" }}
                      >
                        COMPLETAR REGISTRO
                      </Alert>
                    )}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
          {students.length > 0 && students[students.length - 1].complete ? (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Card style={{ height: "100%" }}>
                <CardActionArea style={{ height: "100%" }} onClick={addChild}>
                  <Grid container alignItems="center" justify="center">
                    <CardContent align="center">
                      <Typography variant="h5" component="h2">
                        <PersonAddIcon style={{ fontSize: 50 }} />
                      </Typography>
                    </CardContent>
                  </Grid>
                </CardActionArea>
              </Card>
            </Grid>
          ) : (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Card style={{ height: "100%" }}>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  style={{ height: "100%" }}
                >
                  <CardContent align="center">
                    <Typography
                      variant="body2"
                      component="h2"
                      color="textSecondary"
                    >
                      Para agregar una nueva postulación, primero tiene que
                      terminar la última que tiene pendiente.
                    </Typography>
                  </CardContent>
                </Grid>
              </Card>
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default Inicio;
