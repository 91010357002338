class userinfo {
  static getId() {
    let user = JSON.parse(sessionStorage.getItem("react-user"));
    return user.value.data._id;
  }

  static getSchool_id() {
    let user = JSON.parse(sessionStorage.getItem("react-user"));
    return user.value.data.college;
  }

  static getUser() {
    let user = JSON.parse(sessionStorage.getItem("react-user"));
    return user.value.data.user;
  }
}

export default userinfo;
