import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

const SelectCustodia = ({ value, onChange, title = "Custodia" }) => {
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>{title}</InputLabel>
      <Select value={value} onChange={onChange} label={title}>
        <MenuItem value="PADRE">PADRE</MenuItem>
        <MenuItem value="MADRE">MADRE</MenuItem>
        <MenuItem value="APODERADO">APODERADO</MenuItem>
      </Select>
    </FormControl>
  );
};

export default SelectCustodia;
