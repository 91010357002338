const API_FORM_URL = "https://nap-matricula-form.herokuapp.com/api";
//const API_FORM_URL = "http://192.168.1.119:8010/api";
//const API_FORM_URL = "http://localhost:8010/api";
const API_CLASSROOM_URL = "https://netappperu.xyz/api";
//const API_CLASSROOM_URL = "http://192.168.1.119:8080/api";

function api(type) {
  switch (type) {
    case "form":
      return API_FORM_URL;
    case "classroom":
      return API_CLASSROOM_URL;
    default:
      return API_FORM_URL;
  }
}

export default api;
