import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import { Link } from "react-router-dom";

export default function FeaturedPost(props) {
  const { post } = props;

  return (
    <Grid item xs={12} md={6}>
      <Card>
        <Link to={{ pathname: post.link }}>
          <CardActionArea>
            <CardContent>
              <Typography component="h1" variant="h4" align="center">
                {post.title}
                <br />
                <br />
                <post.icon color="primary" style={{ fontSize: 75 }} />
              </Typography>
              <br />
              <Typography
                component="h2"
                variant="body2"
                color="textSecondary"
                align="center"
              >
                {post.desc}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Link>
      </Card>
    </Grid>
  );
}

FeaturedPost.propTypes = {
  post: PropTypes.object,
};
