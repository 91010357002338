import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import posthttps from "../../../hook/fetch/posthttps";
import { useParams } from "react-router-dom";
import useForm from "react-hook-form";
import SelectCountries from "../../../utils/SelectCountries";
import Spinner from "../../Spinner/Spinner";
import { TextField, Grid, FormHelperText, Box } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import SelectGender from "../../../utils/SelectGender";
import moment from "moment";

const DatosGenerales = (props) => {
  const { idStudent } = useParams();
  const { handleSubmit, register, errors } = useForm();
  const [firstName, setfirstName] = useState(null);
  const [genero, setgenero] = useState("MASCULINO");
  const [surname, setsurname] = useState(null);
  const [secondSurname, setsecondSurname] = useState(null);
  const [secondName, setSecondName] = useState(null);
  const [loading, setloading] = useState(true);
  const [email, setEmail] = useState(null);

  const [country, setcountry] = useState(null);
  const [birthday, setbirthday] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );

  async function infoUser() {
    let obj = {
      id: idStudent,
    };
    let res = await posthttps("infouser", obj);
    if (res.data.postulant.datosGenerales !== undefined) {
      setfirstName(res.data.postulant.datosGenerales.firstName);
      setsecondSurname(res.data.postulant.datosGenerales.secondSurname);
      setsurname(res.data.postulant.datosGenerales.surname);
      setbirthday(moment(res.data.postulant.datosGenerales.birthday).format("YYYY-MM-DD"));
      setgenero(res.data.postulant.datosGenerales.genero);
      setcountry(res.data.postulant.datosGenerales.country);
      setSecondName(res.data.postulant.datosGenerales.secondName);
      setEmail(res.data.postulant.datosGenerales.email);
    }
    setloading(false);
  }

  useEffect(() => {
    infoUser();
  }, []);

  async function saveDatos(values) {
    if (country) {
      setloading(true);
      let postulant = {
        firstName: values.name,
        secondSurname: secondSurname,
        surname: values.surname,
        secondName: secondName,
        genero,
        country,
        birthday,
        email: values.email,
      };
      let obj = {
        id: idStudent,
        datos: postulant,
        relationship: "postulant",
        dato: "datosGenerales",
      };
      await posthttps("datosgenerales", obj);
      props.handleNext();
      setloading(true);
    }
  }

  return (
    <Box p={2}>
      {loading ? (
        <Spinner />
      ) : (
        <form onSubmit={handleSubmit(saveDatos)} autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Apellido paterno"
                variant="outlined"
                fullWidth
                id="surname"
                defaultValue={surname}
                name="surname"
                inputRef={register({
                  required: "Apellido paterno obligatorio",
                })}
              />
              <FormHelperText error>
                {errors.surname && errors.surname.message}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Apellido materno"
                variant="outlined"
                fullWidth
                value={secondSurname}
                onChange={(e) => setsecondSurname(e.target.value.toUpperCase())}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Primer nombre"
                variant="outlined"
                fullWidth
                defaultValue={firstName}
                name="name"
                inputRef={register({
                  required: "Nombre Obligatorio",
                })}
              />
              <FormHelperText error>
                {errors.name && errors.name.message}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Segundo nombre"
                variant="outlined"
                fullWidth
                value={secondName}
                onChange={(e) => setSecondName(e.target.value.toUpperCase())}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectGender
                value={genero}
                onChange={(e) => setgenero(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectCountries
                value={country}
                onChange={(e) => setcountry(e.target.value)}
              />
              {country == null && (
                <FormHelperText error>País Obligatorio</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Fecha de nacimiento"
                variant="outlined"
                type="date"
                fullWidth
                defaultValue={birthday}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setbirthday(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Correo electrónico"
                variant="outlined"
                fullWidth
                name="email"
                defaultValue={email}
                InputLabelProps={{ shrink: true }}
                inputRef={register({
                  required: "Email Obligatorio",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Email invalido",
                  },
                })}
              />
              <FormHelperText error>
                {errors.email && errors.email.message}
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={() => props.handleBackItem()}
                variant="contained"
                color="primary"
              >
                Atras
              </Button>{" "}
              <Button type="submit" variant="contained" color="secondary">
                Siguiente <NavigateNextIcon />
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Box>
  );
};

export default DatosGenerales;
