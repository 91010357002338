import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import useForm from "react-hook-form";
import {
  Grid,
  TextField,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import { FormHelperText } from "@material-ui/core";
import { notification } from "antd";
import posthttps from "../../hook/fetch/posthttps";

const useStyles = makeStyles((theme) => ({
  centered: {
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
  },
  image: {
    height: 300,
    width: 300
  }
}));

export default function ForgotPassword() {
  const classes = useStyles();
  const { handleSubmit, register, errors } = useForm();
  const [page, setPage] = useState({
    form: true,
    success: false,
    error: false,
  });

  const onSubmit = async (val) => {
    try {
      const values = {
        user: val.email,
      };

      const data = await posthttps("auth/forgot-password", values, "form");
      
      if ( data.error ) {
        notification["error"]({
          message: "Recovery password",
          description:
            "No se encontro registro de su correo. Verifique el correo ingresado",
        });
        return;
      }

      setPage({
        form: false,
        success: true,
        error: false
      });
    } catch (error) {
      setPage({
        form: false,
        success: false,
        error: true
      });
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        <Container maxWidth="sm" className={classes.centered}>
          {page.form && (
            <Card>
              <CardContent>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h3" component="h1">
                        <b>RECUPERAR CUENTA</b>
                      </Typography>
                      <Typography variant="body2" component="h2">
                        Para recuperar la clave, introduzca los datos
                        solicitados. En caso no pueda continuar, por favor
                        enviar un correo a napmasterperu@gmail.com
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Correo"
                        name="email"
                        variant="outlined"
                        fullWidth
                        inputRef={register({
                          required: "Email Obligatorio",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Email invalido",
                          },
                        })}
                      />
                      <FormHelperText error>
                        {errors.email && errors.email.message}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                      <Button variant="contained" type="submit" color="primary">
                        Enviar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          )}

          {page.success && (
            <Card>
              <CardContent className={classes.content}>
                <img
                  className={classes.image}
                  alt="Success Mail"
                  src="/static/images/success.gif"
                />
                <Typography
                  variant="body1"
                  component="p"
                  align="center"
                  style={{ paddingTop: 20 }}
                >
                  Se envió el enlace para reestablecer su contraseña a su
                  correo.
                </Typography>
                <br />
              </CardContent>
            </Card>
          )}

          {page.error && (
            <Card>
              <CardContent className={classes.content}>
                <img
                  className={classes.image}
                  alt="Success Mail"
                  src="/static/images/errorsend.gif"
                />
                <Typography
                  variant="body1"
                  component="p"
                  align="center"
                  style={{ paddingTop: 20 }}
                >
                  Ocurrió un error, el correo ingresado no concuerdan con
                  nuestros registros.
                </Typography>
                <br />
              </CardContent>
            </Card>
          )}
        </Container>
      </main>
    </React.Fragment>
  );
}
