import { useState, useEffect } from "react";
import UserServices from "../services/UserServices";
import api from "../config/api";
const useHttp = (url, dependencies) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedData, setfetchedData] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    fetch(`${api("form")}/${url}`, UserServices.createInit("GET"))
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch.");
        }
        return response.json();
      })
      .then((data) => {
        setIsLoading(false);
        setfetchedData(data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, dependencies);
  return [isLoading, fetchedData];
};
export default useHttp;
