import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  makeStyles,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import api from '../../config/api';

const useStyles = makeStyles(theme => ({
  content_confirm: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    display: 'flex',
    flexFlow: 'column',
    width: 333
  },
  image: {
    height: 300,
    width: 300
  }
}));

export default () => {
  const classes = useStyles();
  let { token } = useParams();
  const [page, setPage] = useState({
    confirm: true,
    success: false,
    error: false
  });

  const confirmReset = async () => {
    await axios
      .post(`${api( 'form' )}/auth/reset_password`, {
        token: token
      })
      .then(response => {
        setPage({
          confirm: false,
          success: true,
          error: false
        });
      })
      .catch(error => {
        setPage({
          confirm: false,
          success: false,
          error: true
        });
      });
  };

  return (
    <div className={classes.content_confirm}>
      {page.confirm && (
        <Card>
          <CardContent className={classes.content}>
            <img
              className={classes.image}
              alt="New Password"
              src="/static/images/confirm.gif"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => confirmReset()}
            >
              reestablecer
            </Button>
          </CardContent>
        </Card>
      )}

      {page.success && (
        <Card>
          <CardContent className={classes.content}>
            <img
              className={classes.image}
              alt="Success"
              src="/static/images/success.gif"
            />
            <Typography
              variant="h4"
              component="p"
              align="center"
              style={{ paddingTop: 20 }}
            >
              Se reestableció correctamente su contraseña. <br />
            </Typography>
            <Typography variant="body1" component="p" align="center">
              Cierre esta ventana y <b>verifique su correo.</b>
            </Typography>
          </CardContent>
        </Card>
      )}

      {page.error && (
        <Card>
          <CardContent className={classes.content}>
            <img
              className={classes.image}
              alt="New Password"
              src="/static/images/errorsend.gif"
            />
            <Typography variant="h4" component="p" align="center">
              No se logró reestablecer su contraseña. <br />
            </Typography>
            <Typography variant="body1" component="p" align="center">
              Es probable que el token expiró.
              <br />
              <b>Cierre esta ventana e intente otra vez.</b>
            </Typography>
          </CardContent>
        </Card>
      )}
    </div>
  );
};
