import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

const SelectLevel = ({ value, onChange }) => {
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>Nivel al que postula</InputLabel>
      <Select value={value} onChange={onChange} label="Nivel al que postula">
        <MenuItem value="INICIAL">INICIAL</MenuItem>
        <MenuItem value="PRIMARIA">PRIMARIA</MenuItem>
        <MenuItem value="SECUNDARIA">SECUNDARIA</MenuItem>
      </Select>
    </FormControl>
  );
};

export default SelectLevel;
