import React, { useState, useEffect } from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import posthttps from "../hook/fetch/posthttps";

const SelectEstadoCivil = (props) => {
  const { value = null, onChange, province } = props;
  const [distritos, setdistritos] = useState([]);

  useEffect(() => {
    if (province) {
      obtainDistritos();
    }
  }, [province]);

  const obtainDistritos = async () => {
    let obj = {
      province: province,
    };
    let res = await posthttps("distritos", obj);
    setdistritos(res);
  };

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>Distritos</InputLabel>
      {distritos.length > 0 && (
        <Select value={value} onChange={onChange} label="Distritos">
          {distritos.map((ev) => (
            <MenuItem value={ev}>{ev}</MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
};

export default SelectEstadoCivil;
