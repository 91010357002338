import React, { useState, useEffect } from "react";
import {
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  Grid,
} from "@material-ui/core";
import posthttps from "../../../../hook/fetch/posthttps";
import userinfo from "../../../../config/user";
import Spinner from "../../../Spinner/Spinner";
import { useParams } from "react-router-dom";

const DataGeneral = (props) => {
  const { idStudent } = useParams();
  const [loading, setloading] = useState(true);
  const [value, setvalue] = useState(1);

  async function infoUser() {
    setloading(true);
    let obj = {
      id: idStudent,
    };
    let res = await posthttps("infouser", obj);
    let arrayStatus = [
      "Trabaja en el Perú",
      "Trabaja fuera del Perú",
      "Jubilado",
      "Otros",
    ];

    if (res.data.father.datosEmpleo !== undefined) {
      setvalue(
        arrayStatus.indexOf(res.data.father.datosEmpleo.dataGeneral) + 1
      );
    }
    setloading(false);
  }

  useEffect(() => {
    infoUser();
  }, []);

  return loading ? (
    <Spinner />
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <RadioGroup
          onChange={(e) => setvalue(Number(e.target.value))}
          value={value}
        >
          <FormControlLabel
            value={1}
            control={<Radio color="primary" />}
            label="Trabaja en el Perú"
          />
          <FormControlLabel
            value={2}
            control={<Radio color="primary" />}
            label="Trabaja fuera del Perú"
          />
          <FormControlLabel
            value={3}
            control={<Radio color="primary" />}
            label="Es jubilado o retirado"
          />
          <FormControlLabel
            value={4}
            control={<Radio color="primary" />}
            label="Otros"
          />
        </RadioGroup>
      </Grid>
      <Grid item xs={12}>
        {props.activeStep === 0 ? (
          <Button
            variant="contained"
            color="primary"
            onClick={props.handleBackItem}
          >
            ATRAS
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={props.handleBack}
          >
            ATRAS
          </Button>
        )}{" "}
        {value !== 1 && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => props.goToStep3(value)}
          >
            Siguiente
          </Button>
        )}
        {value === 1 && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => props.gotoNext(value)}
          >
            Siguiente
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default DataGeneral;
