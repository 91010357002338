import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

const SelectCodigos = ({ value, onChange }) => {
  return (
    <FormControl variant="outlined" style={{ width: 60 }}>
      <InputLabel>Cod</InputLabel>
      <Select value={value} onChange={onChange} label="Cod">
        <MenuItem value="01">01</MenuItem>
        <MenuItem value="41">41</MenuItem>
        <MenuItem value="43">43</MenuItem>
        <MenuItem value="83">83</MenuItem>
        <MenuItem value="54">54</MenuItem>
        <MenuItem value="66">66</MenuItem>
        <MenuItem value="76">76</MenuItem>
        <MenuItem value="84">84</MenuItem>
        <MenuItem value="67">67</MenuItem>
        <MenuItem value="62">62</MenuItem>
        <MenuItem value="56">56</MenuItem>
        <MenuItem value="64">64</MenuItem>
        <MenuItem value="44">44</MenuItem>
        <MenuItem value="74">74</MenuItem>
        <MenuItem value="65">65</MenuItem>
        <MenuItem value="82">82</MenuItem>
        <MenuItem value="53">53</MenuItem>
        <MenuItem value="63">63</MenuItem>
        <MenuItem value="73">73</MenuItem>
        <MenuItem value="51">51</MenuItem>
        <MenuItem value="42">42</MenuItem>
        <MenuItem value="52">52</MenuItem>
        <MenuItem value="72">72</MenuItem>
        <MenuItem value="61">61</MenuItem>
      </Select>
    </FormControl>
  );
};

export default SelectCodigos;
