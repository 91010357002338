import React, { useState, useEffect, useRef } from "react";
import {
  Checkbox,
  FormControlLabel,
  Box,
  CardContent,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import ReactToPrint from "react-to-print";
import Details from "./components/Details";
import Button from "@material-ui/core/Button";
import posthttps from "../../../hook/fetch/posthttps";
import gethttps from "../../../hook/fetch/gethttps";
import infouser from "../../../config/user";
import { notification } from "antd";
import Spinner from "../../Spinner/Spinner";
import { useParams } from "react-router-dom";

const Resumen = () => {
  const componentRef = useRef(null);
  const { idStudent } = useParams();
  const [value, setvalue] = useState(false);
  const [data, setdata] = useState();
  const [loading, setloading] = useState(true);
  const [schoolData, setSchoolData] = useState(null);

  async function completeInfo() {
    setloading(true);
    try {
      await posthttps("solicitudenviada", {
        id: idStudent,
        complete: true,
      });
      //let res = await posthttps("create/postulation", data, "classroom");
      notification["success"]({
        message: "Su solicitud ha sido enviada satisfactoriamente",
      });
      window.location.href = "/";
    } catch (error) {
      notification["error"]({
        message: "Ocurrió un error al completar el registro.",
        description: `${error}`,
      });
    } finally {
      setloading(false);
    }
  }

  async function infoUser() {
    setloading(true);
    let obj = {
      id: idStudent,
    };
    let res = await posthttps("infouser", obj);
    let resSchool = await gethttps(
      `school/${infouser.getSchool_id()}`,
      "classroom"
    );
    setSchoolData(resSchool.school);
    setdata(res.data);
    setloading(false);
  }

  useEffect(() => {
    infoUser();
  }, []);

  return (
    <Box p={2}>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <CardContent ref={componentRef}>
            <Details
              father={data.father}
              mother={data.mother}
              attorney={data.attorney}
              postulant={data.postulant}
              school={schoolData}
            />
          </CardContent>
          {!data.complete ? (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value}
                    onChange={(e) => setvalue(e.target.checked)}
                  />
                }
                label="Estoy deacuerdo con la información brindada"
              />
              <br />
              <Button
                variant="contained"
                color="secondary"
                disabled={!value}
                onClick={() => completeInfo()}
              >
                Enviar Solicitud
              </Button>
            </>
          ) : (
            <ReactToPrint
              trigger={() => (
                <Button color="secondary" variant="contained">
                  <GetAppIcon /> DESCARGAR
                </Button>
              )}
              content={() => componentRef.current}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default Resumen;
