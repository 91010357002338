import React, { useState, useEffect } from "react";
import {
  Button,
  Radio,
  RadioGroup,
  Box,
  Grid,
  FormControlLabel,
  TextField,
  FormHelperText,
  Typography,
} from "@material-ui/core";
import SelectEstadoCivil from "../../../utils/SelectEstadoCivil";
import posthttps from "../../../hook/fetch/posthttps";
import useForm from "react-hook-form";
import Spinner from "../../Spinner/Spinner";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import SelectTypeDocument from "../../../utils/SelectTypeDocument";
import { useParams } from "react-router-dom";
import moment from "moment";

const DatosGenerales = (props) => {
  const { idStudent } = useParams();
  const { handleSubmit, register, errors } = useForm();
  const [apoderado, setapoderado] = useState("no");
  const [firstName, setfirstName] = useState(null);
  const [secondSurname, setsecondSurname] = useState(null);
  const [surname, setsurname] = useState(null);
  const [secondName, setsecondName] = useState(null);
  const [loading, setloading] = useState(true);
  const [typeDocument, settypeDocument] = useState("DNI");
  const [email1, setemail1] = useState(null);
  const [email2, setemail2] = useState(null);
  const [birthday, setbirthday] = useState(
    moment(Date.now()).utc(false).format("YYYY-MM-DD")
  );
  const [numberDocument, setnumberDocument] = useState(null);
  const [civilState, setcivilState] = useState("soltero");

  async function infoUser() {
    let obj = {
      id: idStudent,
    };
    let res = await posthttps("infouser", obj);
    if (res.data.attorney.datosGenerales !== undefined) {
      let resData = res.data.attorney.datosGenerales;
      setapoderado(resData.apoderado);
      setfirstName(resData.firstName);
      setsecondSurname(resData.secondSurname);
      setsurname(resData.surname);
      setsecondName(resData.secondName);
      if (resData.alive === 1) {
        setemail1(resData.email1);
        setemail2(resData.email2);
        setbirthday(resData.birthday);
        setnumberDocument(resData.numberDocument);
        setcivilState(resData.civilState);
        settypeDocument(resData.typeDocument);
      }
    }
    setloading(false);
  }

  useEffect(() => {
    infoUser();
  }, []);

  async function saveDatos(values) {
    setloading(true);
    let attorney = {
      firstName: values.name ? values.name : "",
      secondSurname: values.name ? secondSurname : "",
      surname: values.surname ? values.surname : "",
      secondName: values.name ? secondName : "",
      alive: values.name ? 1 : "",
      typeDocument: values.name ? typeDocument : "",
      numberDocument: values.name ? values.numberDocument : "",
      birthday: values.name ? birthday : "",
      civilState: values.name ? civilState : "",
      email1: values.name ? values.email1 : "",
      email2: values.name ? values.email2 : "",
      apoderado: apoderado,
    };
    let obj = {
      id: idStudent,
      datos: attorney,
      relationship: "attorney",
      dato: "datosGenerales",
    };
    await posthttps("datosgenerales", obj);
    if (apoderado === "si") {
      props.handleNext();
    } else {
      props.handleNextItem();
    }
    setloading(false);
  }

  function handler(id) {
    const elt = document.getElementById(`${id}`);
    elt.value = elt.value.toUpperCase();
  }

  return (
    <Box p={2}>
      {loading ? (
        <Spinner />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              <b>
                ¿Es la persona responsable del solicitante otra persona que no
                sea la madre o el padre?
              </b>
            </Typography>
            <RadioGroup
              onChange={(e) => setapoderado(e.target.value)}
              value={apoderado}
            >
              <FormControlLabel
                value="si"
                control={<Radio color="primary" />}
                label="SI"
              />
              <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                label="NO"
              />
            </RadioGroup>
          </Grid>
          {apoderado == "si" && (
            <form onSubmit={handleSubmit(saveDatos)} autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Apellido paterno"
                    fullWidth
                    id="surname"
                    onKeyUp={(e) => handler("surname")}
                    defaultValue={surname}
                    name="surname"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    inputRef={register({
                      required: "Apellido Obligatorio",
                    })}
                  />
                  <FormHelperText error>
                    {errors.surname && errors.surname.message}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Apellido materno"
                    variant="outlined"
                    fullWidth
                    value={secondSurname}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      setsecondSurname(e.target.value.toUpperCase())
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Nombre"
                    variant="outlined"
                    fullWidth
                    id="firstName"
                    onKeyUp={(e) => handler("firstName")}
                    defaultValue={firstName}
                    name="name"
                    InputLabelProps={{ shrink: true }}
                    inputRef={register({
                      required: "Nombre Obligatorio",
                    })}
                  />
                  <FormHelperText error>
                    {errors.name && errors.name.message}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Segundo nombre"
                    variant="outlined"
                    fullWidth
                    value={secondName}
                    onChange={(e) =>
                      setsecondName(e.target.value.toUpperCase())
                    }
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SelectTypeDocument
                    value={typeDocument}
                    onChange={(e) => settypeDocument(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Número de documento"
                    variant="outlined"
                    fullWidth
                    defaultValue={numberDocument}
                    name="numberDocument"
                    InputLabelProps={{ shrink: true }}
                    inputRef={register({
                      required: "Número de documento obligatorio",
                      pattern: {
                        value: /^[0-9A-Z]{7,15}$/i,
                        message: "Número invalido",
                      },
                    })}
                  />
                  <FormHelperText error>
                    {errors.numberDocument && errors.numberDocument.message}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Fecha de nacimiento"
                    variant="outlined"
                    type="date"
                    fullWidth
                    defaultValue={birthday}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setbirthday(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SelectEstadoCivil
                    value={civilState}
                    onChange={(e) => setcivilState(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Correo electrónico"
                    variant="outlined"
                    fullWidth
                    name="email1"
                    defaultValue={email1}
                    InputLabelProps={{ shrink: true }}
                    inputRef={register({
                      required: "Email Obligatorio",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Email invalido",
                      },
                    })}
                  />
                  <FormHelperText error>
                    {errors.email1 && errors.email1.message}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="secondary">
                    Siguiente <NavigateNextIcon />
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
          {apoderado === "no" && (
            <Button
              type="button"
              variant="contained"
              color="secondary"
              onClick={saveDatos}
            >
              Siguiente <NavigateNextIcon />
            </Button>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default DatosGenerales;
