import React, { useState, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import MainFeaturedPost from "./MainFeaturedPost";
import api from "../../config/api";
import { useHistory } from "react-router-dom";
import { notification } from "antd";
import useForm from "react-hook-form";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Divider,
} from "@material-ui/core";
import Spinner from "../../views/Spinner/Spinner";
import posthttps from "../../hook/fetch/posthttps";
import { DialogActions } from "@material-ui/core";

export default function Register(props) {
  const history = useHistory();
  const { handleSubmit, register, errors } = useForm();
  const [visible, setVisible] = useState(false);
  const [checked, setchecked] = useState(false);
  const [loading, setloading] = useState(false);
  const [loadingSpinner, setloadingSpinner] = useState(true);
  const [data, setdata] = useState();
  const [idCollege, setidCollege] = useState();
  const [relationship, setrelationship] = useState("father");
  function onChange(e) {
    setchecked(e.target.checked);
  }

  async function onSubmit(values) {
    setloading(true);
    let body = {
      user: values.email.toLowerCase(),
      password: values.password,
      firstName: values.firstName.toUpperCase(),
      secondName: values.secondName.toUpperCase(),
      surname: values.surname.toUpperCase(),
      secondSurname: values.secondSurname.toUpperCase(),
      relationship: relationship,
      college: idCollege,
      registered: relationship,
    };
    let response = await fetch(`${api("form")}/register`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let data = await response.json();
    setloading(false);
    if (response.status == "200") {
      notification["success"]({
        message: "Usuario registrado correctamente.",
        description:
          "La cuenta fue creada correctamente, puede revisar su correo.",
      });
      history.push(`/${props.match.params.id}/login`);
    } else {
      notification["error"]({
        message: "Error",
        description: "Ya existe un usuario con ese correo.",
      });
    }
  }

  useEffect(() => {
    getCollege();
  }, []);

  async function getCollege() {
    let obj = {
      url: props.match.params.id,
    };
    let res = await posthttps("school/url", obj, "classroom");
    setdata(res);
    setloadingSpinner(false);
    setidCollege(res._id);
  }

  if (loadingSpinner || data == null) {
    return <Spinner />;
  } else {
    return (
      <React.Fragment>
        <CssBaseline />
        <main>
          <MainFeaturedPost post={data} />
          <Container style={{ marginTop: "-25vh" }} maxWidth="md">
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography component="h1" variant="h5">
                      INGRESE LA INFORMACIÓN DE UNO DE LOS PADRES
                    </Typography>
                    <Typography component="h2" variant="body1">
                      Usted deberá registrar los siguientes datos para que pueda
                      iniciar el proceso de postulación.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Apellido paterno"
                            variant="outlined"
                            fullWidth
                            name="surname"
                            inputRef={register({
                              required: "Apellido paterno requerido",
                            })}
                          />
                          <FormHelperText error>
                            {errors.surname && errors.surname.message}
                          </FormHelperText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Apellido materno"
                            variant="outlined"
                            fullWidth
                            name="secondSurname"
                            inputRef={register({
                              required: "Apellido materno requerido",
                            })}
                          />
                          <FormHelperText error>
                            {errors.secondSurname &&
                              errors.secondSurname.message}
                          </FormHelperText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Primer nombre"
                            variant="outlined"
                            fullWidth
                            name="firstName"
                            inputRef={register({
                              required: "Nombre requerido",
                            })}
                          />
                          <FormHelperText error>
                            {errors.firstName && errors.firstName.message}
                          </FormHelperText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Segundo nombre"
                            variant="outlined"
                            fullWidth
                            name="secondName"
                            inputRef={register({})}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel>Parentesco</InputLabel>
                            <Select
                              label="parentesco"
                              value={relationship}
                              onChange={(e) => {
                                setrelationship(e.target.value);
                              }}
                            >
                              <MenuItem value="father">Padre</MenuItem>
                              <MenuItem value="mother">Madre</MenuItem>
                              <MenuItem value="attorney">Apoderado/a</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Correo"
                            variant="outlined"
                            fullWidth
                            name="email"
                            inputRef={register({
                              required: "Email requerido",
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Email invalido",
                              },
                            })}
                          />
                          <FormHelperText error>
                            {errors.email && errors.email.message}
                          </FormHelperText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Contraseña"
                            variant="outlined"
                            fullWidth
                            name="password"
                            type="password"
                            inputRef={register({
                              required: "Contraseña Obligatoria",
                              minLength: 6,
                            })}
                          />
                          <FormHelperText error>
                            {errors.password && errors.password.message}
                          </FormHelperText>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={onChange}
                                checked={checked}
                                name="checkedA"
                              />
                            }
                            label={
                              <span>
                                Acepto la
                                <Button
                                  color="primary"
                                  onClick={() => setVisible(true)}
                                >
                                  POLITICA DE PRIVACIDAD
                                </Button>
                                del colegio {data.tradeName.toUpperCase()}.
                              </span>
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={!checked}
                            loading={loading}
                          >
                            REGISTRAR
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Container>
          <Dialog open={visible} onClose={() => setVisible(false)}>
            <DialogTitle>
              <FormHelperText align="center" component="h1" variant="h2">
                TERMINOS Y CONDICIONES
              </FormHelperText>
            </DialogTitle>
            <DialogContent>
              <FormHelperText variant="body1" color="textSecondary">
                What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the
                printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled it to make a
                type specimen book. It has survived not only five centuries, but
                also the leap into electronic typesetting, remaining essentially
                unchanged. It was popularised in the 1960s with the release of
                Letraset sheets containing Lorem Ipsum passages, and more
                recently with desktop publishing software like Aldus PageMaker
                including versions of Lorem Ipsum. Why do we use it? It is a
                long established fact that a reader will be distracted by the
                readable content of a page when looking at its layout. The point
                of using Lorem Ipsum is that it has a more-or-less normal
                distribution of letters, as opposed to using 'Content here,
                content here', making it look like readable English. Many
                desktop publishing packages and web page editors now use Lorem
                Ipsum as their default model text, and a search for 'lorem
                ipsum' will uncover many web sites still in their infancy.
                Various versions have evolved over the years, sometimes by
                accident, sometimes on purpose (injected humour and the like).
              </FormHelperText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setVisible(false)}
              >
                CERRAR
              </Button>
            </DialogActions>
          </Dialog>
        </main>
      </React.Fragment>
    );
  }
}
