import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { Grid, Typography, Box } from "@material-ui/core";
import GeneralInfo from "./GeneralInfo";

const Details = (props) => {
  const { father, mother, attorney, postulant, school } = props;

  return (
    <Box p={5}>
      <Typography variant="h3" align="center">
        FICHA DE INSCRIPCIÓN
      </Typography>
      <Grid container justify="space-between">
        <Grid item>
          <Typography variant="h4" color="textSecondary">
            COLEGIO:{" "}
            <span style={{ textTransform: "uppercase" }}>
              {school.tradeName}
            </span>
          </Typography>
          <Typography variant="h5" color="textSecondary">
            RUC: <span>{school.ruc}</span>
          </Typography>
          <Typography variant="h5" color="textSecondary">
            DIRECCIÓN:{" "}
            <span style={{ textTransform: "capitalize" }}>
              {school.address}
            </span>
          </Typography>
        </Grid>
        <Grid item>
          <Avatar
            alt="Logo Colegio"
            style={{ height: "75px", width: "75px" }}
            src={school.logo}
          />
        </Grid>
      </Grid>

      {postulant && (
        <GeneralInfo
          title="INFORMACIÓN DEL POSTULANTE"
          general={postulant.datosGenerales}
          direccion={postulant.direccion}
          empleo={postulant.datosEmpleo}
          adicional={postulant.datosAdicionales}
          documents={postulant.documentos}
        />
      )}

      {father && (
        <GeneralInfo
          title="INFORMACIÓN DEL PADRE"
          general={father.datosGenerales}
          direccion={father.direccion}
          empleo={father.datosEmpleo}
          adicional={father.datosAdicionales}
        />
      )}

      {mother && (
        <GeneralInfo
          title="INFORMACIÓN DE LA MADRE"
          general={mother.datosGenerales}
          direccion={mother.direccion}
          empleo={mother.datosEmpleo}
          adicional={mother.datosAdicionales}
        />
      )}

      {attorney && (
        <GeneralInfo
          title="INFORMACIÓN DEL APODERADO"
          general={attorney.datosGenerales}
          direccion={attorney.direccion}
          empleo={attorney.datosEmpleo}
          adicional={attorney.datosAdicionales}
        />
      )}
    </Box>
  );
};

export default Details;
