import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

const SelectYears = ({ value, onChange }) => {
  const [years, setYears] = useState([]);
  let ano = new Date().getFullYear();

  useEffect(() => {
    let arrayanio = [];
    let anio = ano - 1;
    for (let index = 0; index < 70; index++) {
      arrayanio.push(anio);
      anio--;
    }
    setYears(arrayanio);
  }, []);

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>Seleccione un año</InputLabel>
      <Select value={value} onChange={onChange} label="Seleccione un año">
        {years.map((ev) => (
          <MenuItem value={ev}>{ev}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectYears;
