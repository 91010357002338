import React, { useState, useEffect } from "react";
import useForm from "react-hook-form";
import {
  Button,
  Box,
  Grid,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import posthttps from "../../../hook/fetch/posthttps";
import moment from "moment";
import { notification } from "antd";
import Spinner from "../../Spinner/Spinner";
import SelectCustodia from "../../../utils/SelectCustodia";
import SelectLevel from "../../../utils/SelectLevel";
import SelectGrade from "../../../utils/SelectGrade";
import { useParams } from "react-router-dom";

const InformacionAdicional = (props) => {
  const { idStudent } = useParams();
  const [loading, setloading] = useState(true);
  const [custodia, setcustodia] = useState("PADRE");
  const [nivel, setnivel] = useState("INICIAL");
  const [vive, setvive] = useState("PADRE");
  const [pago, setpago] = useState("PADRE");
  const [grado, setgrado] = useState("3");
  const [expirationDate, setexpirationDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );
  const [encargado, setEncargado] = useState("PADRE");
  const { handleSubmit, register, errors } = useForm();
  const [habilitado, sethabilitado] = useState(false);
  const [year, setYear] = useState(moment(Date.now()).format("YYYY"));

  async function infoUser() {
    let obj = {
      id: idStudent,
    };
    let res = await posthttps("infouser", obj);
    if (res.data.postulant.informacionAdicional !== undefined) {
      let result = res.data.postulant.informacionAdicional;
      setexpirationDate(result.expirationDate);
      setgrado(result.grado);
      setvive(result.vive);
      setEncargado(result.encargado);
      setcustodia(result.custodia);
      setpago(result.pago);
      setYear(result.year);
    }
    if (
      res.data.father.datosAdicionales !== undefined ||
      res.data.mother.datosAdicionales !== undefined ||
      res.data.attorney.datosAdicionales !== undefined
    ) {
      sethabilitado(true);
    }
    setloading(false);
  }

  useEffect(() => {
    infoUser();
  }, []);

  async function saveDatos(values) {
    let postulant = {
      custodia,
      vive,
      pago,
      encargado,
      grado,
      nivel,
      expirationDate,
      year,
    };
    let obj = {
      id: idStudent,
      datos: postulant,
      relationship: "postulant",
      dato: "informacionAdicional",
    };
    setloading(true);
    await posthttps("datosgenerales", obj);
    setloading(false);

    if (habilitado) {
      props.handleNextItem();
    } else {
      notification["warning"]({
        message: "ERROR DATOS INCOMPLETOS",
        description: "Faltan datos de padre madre o apoderado.",
      });
    }
  }

  return (
    <Box p={2}>
      {loading ? (
        <Spinner />
      ) : (
        <form onSubmit={handleSubmit(saveDatos)} autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Fecha de nacimiento"
                variant="outlined"
                type="date"
                fullWidth
                defaultValue={expirationDate}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setexpirationDate(e.target.value)}
              />
              <FormHelperText>Fecha de expedición</FormHelperText>
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectCustodia
                value={custodia}
                onChange={(e) => setcustodia(e.target.value)}
                title="¿Quién tiene la custodia legal?"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectCustodia
                value={vive}
                onChange={(e) => setvive(e.target.value)}
                title="¿Con quién vive el postulante?"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectCustodia
                value={pago}
                onChange={(e) => setpago(e.target.value)}
                title="¿Quien será responsable del pago de la pensión?"
              />
            </Grid>
            <Grid item xs={12}>
              <SelectCustodia
                value={encargado}
                onChange={(e) => setEncargado(e.target.value)}
                title="¿Quien será responsable del seguimiento académico?"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SelectLevel
                value={nivel}
                onChange={(e) => setnivel(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SelectGrade
                level={nivel}
                value={grado}
                onChange={(e) => setgrado(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Año al que postula</InputLabel>
                <Select
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  label="año al que postula"
                >
                  {RenderYears()}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={() => props.handleBack()}
                variant="contained"
                color="primary"
              >
                Atras
              </Button>{" "}
              <Button type="submit" variant="contained" color="secondary">
                Siguiente
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Box>
  );
};

export default InformacionAdicional;

const RenderYears = () => {
  let ano = new Date().getFullYear();

  let arrayanio = [];
  let anio = ano;
  for (let index = 0; index < 5; index++) {
    arrayanio.push(anio);
    anio++;
  }
  return arrayanio.map((ev) => <MenuItem value={ev}>{ev}</MenuItem>);
};
