import React from "react";
import { List, ListSubheader } from "@material-ui/core";
import moment from "moment";
import Item from "./Item";

const GeneralInfo = (props) => {
  const { title, general, direccion, documents } = props;

  return general && general.apoderado && general.apoderado === "no" ? null : (
    <List
      dense={true}
      subheader={
        <ListSubheader component="div">
          <b>{title}</b>
        </ListSubheader>
      }
    >
      {general && (
        <>
          <Item
            title="NOMBRE COMPLETO"
            content={`${general.firstName} ${general.secondName} ${general.surname} ${general.secondSurname}`}
            start={true}
          />
          {general.alive && general.alive === "NO" ? null : (
            <>
              <Item
                title="FECHA DE NACIMIENTO"
                content={`${moment(general.birthday).format("DD/MM/YYYY")}`}
              />
              <Item
                title="DOCUMENTO"
                content={`${
                  general && general.typeDocument
                    ? general.typeDocument
                    : documents && documents.tipoDocumento
                }: ${
                  general && general.numberDocument
                    ? general.numberDocument
                    : documents && documents.nroDocumento
                }`}
              />
              <Item
                title="CORREO"
                content={`${general.email ? general.email : general.email1}`}
                end={true}
              />
            </>
          )}
        </>
      )}
      {direccion && general.alive === "SI" && (
        <>
          <Item
            title="OBIGEO"
            content={`${direccion.departament} - ${direccion.province} - ${direccion.distrito}`}
          />
          <Item title="DIRECCIÓN" content={`${direccion.direccion}`} />
          <Item title="REFERENCIA" content={`${direccion.referencia}`} />
          <Item
            title="DEPARTAMENTO NRO"
            content={`${direccion.departamentoNro}`}
            end={true}
          />
        </>
      )}
    </List>
  );
};

export default GeneralInfo;
