import React, { useState, useEffect } from "react";
import SelectCountries from "../../../../utils/SelectCountries";
import {
  Button,
  FormHelperText,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import posthttps from "../../../../hook/fetch/posthttps";
import userinfo from "../../../../config/user";
import useForm from "react-hook-form";
import SelectCodigos from "../../../../utils/SelectCodigos";
import ReactTelInput from "react-telephone-input";
import "react-telephone-input/css/default.css";
import SelectDepartment from "../../../../utils/SelectDepartament";
import SelectProvincia from "../../../../utils/SelectProvincia";
import SelectDistrito from "../../../../utils/SelectDistrito";
import flagsImagePath from "../../../../assets/img/flags.png";
import Spinner from "../../../Spinner/Spinner";
import { useParams } from "react-router-dom";

const DatosCompañia = (props) => {
  const { idStudent } = useParams();
  const { handleSubmit, register, errors } = useForm();
  const [loading, setloading] = useState(false);

  const [departament, setdepartament] = useState(null);
  const [province, setprovince] = useState(null);
  const [distrito, setdistrito] = useState(null);

  //peru
  const [ruc, setruc] = useState(null);
  const [nameBusiness, setnameBusiness] = useState(null);
  const [naturaleza, setnaturaleza] = useState(null);
  const [domicilio, setdomicilio] = useState(null);
  const [telefono, settelefono] = useState(null);
  const [code, setcode] = useState("01");
  const [puesto, setpuesto] = useState(null);
  const [email, setemail] = useState(null);

  // fuera
  const [country, setcountry] = useState(null);
  const [codeFuera, setcodeFuera] = useState("01");
  const [telefonoFuera, settelefonoFuera] = useState(null);
  const [domicilioFuera, setdomicilioFuera] = useState(null);
  const [ciudadFuera, setciudadFuera] = useState(null);
  const [emailFuera, setemailFuera] = useState(null);

  //jubilado
  const [institucionRetiro, setinstitucionRetiro] = useState(null);

  //otros
  const [descripcionTrabajo, setdescripcionTrabajo] = useState(null);

  //validaciones
  const [ValidationDepartament, setValidationDepartament] = useState(false);
  const [ValidationProvince, setValidationProvince] = useState(false);
  const [ValidationDistrito, setValidationDistrito] = useState(false);

  let arrayStatus = [
    "Trabaja en el Perú",
    "Trabaja fuera del Perú",
    "Jubilado",
    "Otros",
  ];

  let arraySituacion = [
    "Trabajador dependiente",
    "Trabajador independiente",
    "Dueño de su propio negocio",
  ];

  function handleInputChange(telNumber, selectedCountry) {
    settelefonoFuera(telNumber);
    setcodeFuera(selectedCountry);
  }

  async function saveDatos(values) {
    departament == null || departament == "Selecciona un departamento"
      ? setValidationDepartament(true)
      : setValidationDepartament(false);

    province == null || province == "Selecciona una provincia"
      ? setValidationProvince(true)
      : setValidationProvince(false);

    distrito == null || distrito == "Selecciona un Distrito"
      ? setValidationDistrito(true)
      : setValidationDistrito(false);

    let mother = {
      ruc: ruc,
      dataGeneral: arrayStatus[props.status - 1],
      situacionLaboral: arraySituacion[props.situacion - 1],
      //otros
      descripcionTrabajo: values.descriptionWork,
      //jubilado
      institucionRetiro: values.institution,
      //fuera
      paisTrabajo: country,
      emailFueraPeru: values.emailFuera,
      domicilioFueraPeru: values.domicilioFueraPeru,
      ciudadFueraPeru: values.ciudadFueraPeru,
      telefonoFueraPeru: telefonoFuera,
      codePhone: codeFuera,

      //peru
      nombreEmpresa: values.nameBusiness,
      provincia: province,
      departamento: departament,
      distrito: distrito,
      naturalezaNegocio: values.naturaleza,
      domicilio: values.domicilio,
      telefono: values.telefono,
      puesto: values.puesto,
      emailEmpresa: values.email,
      code: code,
    };
    let obj = {
      id: idStudent,
      datos: mother,
      relationship: "mother",
      dato: "datosEmpleo",
    };

    if (
      (!(departament == null || departament == "Selecciona un departamento") &&
        !(province == null || province == "Selecciona una provincia") &&
        !(distrito == null || distrito == "Selecciona un Distrito")) ||
      props.status != 1
    ) {
      setloading(true);
      await posthttps("datosgenerales", obj);
      props.handleNextItem();
      setloading(false);
    }
  }

  async function infoUser() {
    let obj = {
      id: idStudent,
    };
    setloading(true);
    let res = await posthttps("infouser", obj);
    if (res.data.mother.datosEmpleo !== undefined) {
      let dataempleo = res.data.mother.datosEmpleo;
      setruc(dataempleo.ruc);
      setnameBusiness(dataempleo.nombreEmpresa);
      setnaturaleza(dataempleo.naturalezaNegocio);
      setdepartament(dataempleo.departamento);
      setprovince(dataempleo.provincia);
      setdistrito(dataempleo.distrito);
      setdomicilio(dataempleo.domicilio);
      settelefono(dataempleo.telefono);
      setpuesto(dataempleo.puesto);
      setemail(dataempleo.emailEmpresa);
      setcountry(dataempleo.paisTrabajo);
      settelefonoFuera(dataempleo.telefonoFueraPeru);
      setdomicilioFuera(dataempleo.domicilioFueraPeru);
      setciudadFuera(dataempleo.ciudadFueraPeru);
      setemailFuera(dataempleo.emailFueraPeru);
      setdescripcionTrabajo(dataempleo.descripcionTrabajo);
      setinstitucionRetiro(dataempleo.institucionRetiro);
      setcodeFuera(dataempleo.codePhone);
      setcode(dataempleo.code);
    }
    setloading(false);
  }

  useEffect(() => {
    infoUser();
  }, []);

  async function getRuc() {
    let obj = {
      ruc: ruc,
    };
    let res = await posthttps("ruc", obj);
    setnameBusiness(res.nombre_o_razon_social);
    setdepartament(res.departamento);
    setprovince(res.provincia);
    setdistrito(res.distrito);
    setdomicilio(res.direccion);
  }

  return loading ? (
    <Spinner />
  ) : (
    <form onSubmit={handleSubmit(saveDatos)} autoComplete="off">
      <Grid container spacing={2}>
        {props.status == 1 && (
          <>
            <Grid item xs={12} md={6}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="RUC"
                value={ruc}
                onChange={(e) => setruc(e.target.value)}
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton color="primary" onClick={() => getRuc()}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormHelperText error>
                {errors.ruc && errors.ruc.message}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Razón Social"
                variant="outlined"
                fullWidth
                disabled={true}
                value={nameBusiness}
                name="nameBusiness"
                onChange={(e) => setnameBusiness(e.target.value)}
                inputRef={register({
                  required: "Campo Obligatorio",
                })}
              />
              <FormHelperText error>
                {errors.nameBusiness && errors.nameBusiness.message}
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Naturaleza del negocio"
                variant="outlined"
                fullWidth
                value={naturaleza}
                name="naturaleza"
                onChange={(e) => setnaturaleza(e.target.value)}
                inputRef={register({
                  required: "Campo Obligatorio",
                })}
              />
              <FormHelperText error>
                {errors.naturaleza && errors.naturaleza.message}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SelectDepartment
                value={departament}
                onChange={(e) => {
                  setdepartament(e.target.value);
                  setValidationDepartament(false);
                }}
              />
              {ValidationDepartament && (
                <FormHelperText error>Departamento Obligatorio</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SelectProvincia
                onChange={(e) => {
                  setprovince(e.target.value);
                  setValidationProvince(false);
                }}
                value={province}
                departament={departament}
              />
              {ValidationProvince && (
                <FormHelperText error>Provincia obligatoria</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SelectDistrito
                onChange={(e) => {
                  setdistrito(e.target.value);
                  setValidationDistrito(false);
                }}
                value={distrito}
                province={province}
              />
              {ValidationDistrito && (
                <FormHelperText error>Distrito obligatorio</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Domicilio Fiscal"
                variant="outlined"
                fullWidth
                name="domicilio"
                value={domicilio}
                onChange={(e) => setdomicilio(e.target.value)}
                inputRef={register({
                  required: "Domicilio Obligatorio",
                })}
              />
              <FormHelperText error>
                {errors.domicilio && errors.domicilio.message}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                InputLabelProps={{ shrink: true }}
                value="+51"
                variant="outlined"
                style={{ width: 50 }}
                disabled
              />
              <SelectCodigos
                value={code}
                onChange={(e) => setcode(e.target.value)}
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Celular"
                variant="outlined"
                fullWidth
                type="tel"
                inputProps={{ maxLength: 9 }}
                name="telefono"
                value={telefono}
                onChange={(e) => settelefono(e.target.value)}
                inputRef={register({
                  required: "Teléfono Obligatorio",
                })}
                style={{ width: "calc((100%) - 110px)" }}
              />
              <FormHelperText error>
                {errors.telefono && errors.telefono.message}
              </FormHelperText>
            </Grid>
            {props.situacion == 1 && (
              <Grid item xs={12} md={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="Puesto/Ocupación"
                  variant="outlined"
                  fullWidth
                  name="puesto"
                  value={puesto}
                  onChange={(e) => setpuesto(e.target.value)}
                  inputRef={register({
                    required: "Puesto Obligatorio",
                  })}
                />
                <FormHelperText error>
                  {errors.puesto && errors.puesto.message}
                </FormHelperText>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Correo electrónico"
                variant="outlined"
                fullWidth
                value={email}
                onChange={(e) => setemail(e.target.value)}
                type="email"
                name="email"
                inputRef={register({
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Email invalido",
                  },
                })}
              />
              <FormHelperText error>
                {errors.email && errors.email.message}
              </FormHelperText>
            </Grid>
          </>
        )}
        {props.status == 2 && (
          <>
            <Grid item xs={12}>
              <SelectCountries
                value={country}
                onChange={(e) => setcountry(e.target.value)}
              />
              {country === null && (
                <FormHelperText error>País Obligatorio</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Ciudad"
                variant="outlined"
                fullWidth
                defaultValue={ciudadFuera}
                name="ciudadFueraPeru"
                inputRef={register({
                  required: "Campo Obligatorio",
                })}
              />
              <FormHelperText error>
                {errors.ciudadFueraPeru && errors.ciudadFueraPeru.message}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Domicilio"
                variant="outlined"
                fullWidth
                defaultValue={domicilioFuera}
                name="domicilioFueraPeru"
                inputRef={register({
                  required: "Campo Obligatorio",
                })}
              />
              <FormHelperText error>
                {errors.domicilioFueraPeru && errors.domicilioFueraPeru.message}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} md={6}>
              <ReactTelInput
                defaultCountry={codeFuera == "" ? "in" : codeFuera.iso2}
                flagsImagePath={flagsImagePath}
                onChange={handleInputChange}
                value={telefonoFuera}
              />
              <FormHelperText error>
                {errors.telefonoFuera && errors.telefonoFuera.message}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Correo electrónico"
                variant="outlined"
                fullWidth
                defaultValue={emailFuera}
                name="emailFuera"
                inputRef={register({
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Email invalido",
                  },
                })}
              />
              <FormHelperText error>
                {errors.emailFuera && errors.emailFuera.message}
              </FormHelperText>
            </Grid>
          </>
        )}
        {props.status == 3 && (
          <Grid item xs={12}>
            <TextField
              InputLabelProps={{ shrink: true }}
              label="Institución"
              variant="outlined"
              fullWidth
              multiline
              rows={5}
              defaultValue={institucionRetiro}
              name="institution"
              inputRef={register({
                required: "Campo Obligatorio",
              })}
            />
            <FormHelperText error>
              {errors.institution && errors.institution.message}
            </FormHelperText>
          </Grid>
        )}
        {props.status == 4 && (
          <Grid item xs={12}>
            <TextField
              InputLabelProps={{ shrink: true }}
              label="Describa su trabajo"
              variant="outlined"
              fullWidth
              multiline
              rows={5}
              defaultValue={descripcionTrabajo}
              name="descriptionWork"
              inputRef={register({
                required: "Campo Obligatorio",
              })}
            />
            <FormHelperText error>
              {errors.descriptionWork && errors.descriptionWork.message}
            </FormHelperText>
          </Grid>
        )}
        <Grid item xs={12}>
          {props.status !== 1 && (
            <Button
              onClick={() => props.goToStep0()}
              variant="contained"
              color="primary"
            >
              Atras
            </Button>
          )}
          {props.status == 1 && (
            <Button
              onClick={() => props.handleBack()}
              variant="contained"
              color="primary"
            >
              Atras
            </Button>
          )}{" "}
          {props.activeStep == 2 && (
            <Button type="submit" variant="contained" color="secondary">
              Siguiente
            </Button>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default DatosCompañia;
