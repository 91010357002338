import api from "../../config/api";
import UserServices from "../../services/UserServices";

async function posthttps(url,type) {
  let response = await fetch(`${api(type)}/${url}`, {
    method: "GET",
    headers: UserServices.createHeaders()
  });
 
  let data;
  if (response.status == 200) {
    data = await response.json();
   
    return data;
  } else {
    data = [];
    return data;
  }
}

export default posthttps;
