import React, { useState, useEffect } from "react";
import {
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  Grid,
} from "@material-ui/core";
import posthttps from "../../../../hook/fetch/posthttps";
import { useParams } from "react-router-dom";
import Spinner from "../../../Spinner/Spinner";

const DataGeneral = (props) => {
  const { idStudent } = useParams();
  const [loading, setloading] = useState(true);
  const [value, setvalue] = useState(1);

  async function infoUser() {
    setloading(true);
    let obj = {
      id: idStudent,
    };
    let res = await posthttps("infouser", obj);
    let arraySituacion = [
      "Trabajador dependiente",
      "Trabajador independiente",
      "Dueño de su propio negocio",
    ];

    if (res.data.mother.datosEmpleo !== undefined) {
      setvalue(
        arraySituacion.indexOf(res.data.mother.datosEmpleo.situacionLaboral) + 1
      );
    }
    setloading(false);
  }

  useEffect(() => {
    infoUser();
  }, []);

  return loading ? (
    <Spinner />
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <RadioGroup
          onChange={(e) => setvalue(Number(e.target.value))}
          value={value}
        >
          <FormControlLabel
            value={1}
            control={<Radio color="primary" />}
            label="Trabajador dependiente"
          />
          <FormControlLabel
            value={2}
            control={<Radio color="primary" />}
            label="Trabajador independiente"
          />
          <FormControlLabel
            value={3}
            control={<Radio color="primary" />}
            label="Dueño de su propio negocio"
          />
        </RadioGroup>
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={() => props.handleBack()}
          variant="contained"
          color="primary"
        >
          Atras
        </Button>{" "}
        <Button
          variant="contained"
          onClick={() => props.goToStep3(1, value)}
          color="secondary"
        >
          Siguiente
        </Button>
      </Grid>
    </Grid>
  );
};

export default DataGeneral;
