/* eslint-disable no-unused-vars */
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import AuthenticationService from "../../../../auth/AuthenticationService";
import logo from "../../../../assets/img/logoadmin.png";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Button, Toolbar } from "@material-ui/core";
import BackspaceIcon from "@material-ui/icons/Backspace";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  flexGrow: {
    flexGrow: 1,
  },
}));

const TopBar = (props) => {
  const classes = useStyles();

  const handleLogout = () => {
    AuthenticationService.logout().then(() => {
      sessionStorage.removeItem("anio-actual");
      props.history.push("/");
    });
  };

  return (
    <AppBar className={clsx(classes.root)} color="primary">
      <Toolbar>
        <RouterLink to="/">
          <img alt="Logo" src={logo} />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Button
          style={{ color: "white" }}
          onClick={() => handleLogout()}
          startIcon={<BackspaceIcon />}
        >
          Salir
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
