import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

const SelectTypeDocument = ({ value, onChange }) => {
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>Tipo de documento de identidad</InputLabel>
      <Select
        value={value}
        onChange={onChange}
        label="Tipo de documento de identidad"
      >
        <MenuItem value="DNI">DNI</MenuItem>
        <MenuItem value="PASAPORTE">PASAPORTE</MenuItem>
        <MenuItem value="CARNET_EXT">CARNÉ DE EXTRANJERÍA</MenuItem>
        <MenuItem value="PTP">PTP</MenuItem>
      </Select>
    </FormControl>
  );
};

export default SelectTypeDocument;
