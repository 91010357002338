import React, { Suspense } from "react";
import { makeStyles } from "@material-ui/styles";
import { LinearProgress } from "@material-ui/core";
import { TopBar } from "./components";

const Home = (props) => {
  const useStyles = makeStyles(() => ({
    root: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
    },
    topBar: {
      zIndex: 2,
      position: "relative",
    },
    container: {
      display: "flex",
      flex: "1 1 auto",
      overflow: "hidden",
    },
    content: {
      overflowY: "auto",
      flex: "1 1 auto",
    },
  }));

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TopBar history={props.history} className={classes.topBar} />
      <div className={classes.container}>
        <main
          className={classes.content}
          style={{ backgroundColor: "#f4f6f8" }}
        >
          <Suspense fallback={<LinearProgress />}>{props.children}</Suspense>
        </main>
      </div>
    </div>
  );
};

export default Home;
