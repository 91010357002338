import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

const SelectGender = ({ value, onChange }) => {
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>Seleccione un genero</InputLabel>
      <Select value={value} onChange={onChange} label="Seleccione un genero">
        <MenuItem value="MASCULINO">MASCULINO</MenuItem>
        <MenuItem value="FEMENINO">FEMENINO</MenuItem>
        <MenuItem value="OTRO">OTRO</MenuItem>
      </Select>
    </FormControl>
  );
};

export default SelectGender;
