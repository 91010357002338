import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Spinner from "../../views/Spinner/Spinner";
import MainFeaturedPost from "./MainFeaturedPost";
import FeaturedPost from "./FeaturedPost";
import posthttps from "../../hook/fetch/posthttps";
import Container from "@material-ui/core/Container";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { notification } from "antd";
import { Redirect } from "react-router-dom";

const Landing = (props) => {
  const [data, setdata] = useState();
  const [loading, setloading] = useState(true);
  const [featuredPosts, setfeaturedPosts] = useState();
  const [noCollege, setNoCollege] = useState(false);

  async function getCollege() {
    let obj = {
      url: props.match.params.id,
    };
    let res = await posthttps("school/url", obj, "classroom");
    if (res) {
      setdata(res);
      setfeaturedPosts([
        {
          title: "INICIAR SESIÓN",
          desc: "Si ya tienes una cuenta, puedes ingresar a esta dirección para iniciar sesión en el formulario de postulación.",
          link: `/${res.url}/login`,
          icon: VerifiedUserIcon,
        },
        {
          title: "REGISTRO",
          desc: "¿Aún no realizaste tu postulación? Ingresa aquí para comenzar tu postulación.",
          link: `/${res.url}/register`,
          icon: PersonAddIcon,
        },
      ]);
      setloading(false);
    } else {
      notification["info"]({
        message: "Colegio no encontrado",
        description: "Te redigiremos a la vista principal",
      });
      setTimeout(() => setNoCollege(true), 1000);
    }
  }
  useEffect(() => {
    getCollege();
  }, []);

  if (noCollege) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  if (loading || data == null) {
    return <Spinner />;
  } else {
    return (
      <React.Fragment>
        <CssBaseline />
        <main>
          <MainFeaturedPost post={data} />
          <Container maxWidth="md" style={{ marginTop: "-10vh" }}>
            <Grid container spacing={4}>
              {featuredPosts.map((post, index) => (
                <FeaturedPost
                  data={data}
                  key={post.title}
                  post={post}
                  index={index}
                />
              ))}
            </Grid>
          </Container>
        </main>
      </React.Fragment>
    );
  }
};

export default Landing;
