import React, { useState, useEffect } from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import posthttps from "../hook/fetch/posthttps";

const SelectEstadoCivil = (props) => {
  const { value = null, onChange, departament } = props;
  const [provincias, setprovincias] = useState([]);

  useEffect(() => {
    if (departament) {
      obtainProvinces();
    }
  }, [departament]);

  const obtainProvinces = async () => {
    let obj = {
      department: departament,
    };
    let res = await posthttps("provincias", obj);
    setprovincias(res);
  };

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>Provincias</InputLabel>
      {provincias.length > 0 && (
        <Select value={value} onChange={onChange} label="Provincias">
          {provincias.map((ev) => (
            <MenuItem value={ev}>{ev}</MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
};

export default SelectEstadoCivil;
