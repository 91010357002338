import React, { PureComponent } from 'react';
import {Route, Redirect} from 'react-router-dom';
import App from '../../../components/Global/Homes.jsx';
import AuthenticationService from '../../../auth/AuthenticationService';

export const ProtectedRoute = ({component:Component,...rest}) => {
	return (
			<Route {...rest} render={props=>{
				if(AuthenticationService.isUserAuthenticated()===true){
					return <App {...props}><Component {...props}/></App >
				}else{
					return <Redirect to={{
						pathname:"/login",
						state:{
							from: props.location
						}
					}}/>;
				}
			}}></Route>
	);
}