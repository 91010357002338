import React, { useState, useEffect } from "react";
import useForm from "react-hook-form";
import posthttps from "../../../hook/fetch/posthttps";
import {
  Button,
  FormHelperText,
  Grid,
  TextField,
  Divider,
} from "@material-ui/core";
import userinfo from "../../../config/user";
import SelectCodigos from "../../../utils/SelectCodigos";
import SelectDepartment from "../../../utils/SelectDepartament";
import SelectProvincia from "../../../utils/SelectProvincia";
import SelectDistrito from "../../../utils/SelectDistrito";
import Spinner from "../../Spinner/Spinner";
import { useParams } from "react-router-dom";

const Direccion = (props) => {
  const { idStudent } = useParams();
  const [loading, setloading] = useState(true);
  const { handleSubmit, register, errors } = useForm();
  const [departament, setdepartament] = useState();
  const [province, setprovince] = useState();
  const [distrito, setdistrito] = useState();
  const [referencia, setreferencia] = useState("");
  const [departamentoNro, setdepartamentoNro] = useState("");
  const [celular2, setcelular2] = useState("");
  const [celular, setcelular] = useState("");

  const [code, setcode] = useState("01");
  const [code2, setcode2] = useState("01");
  const [telefono, settelefono] = useState("");
  const [telefono2, settelefono2] = useState("");
  const [direccion, setdireccion] = useState("");

  //validaciones

  const [ValidationDepartament, setValidationDepartament] = useState(false);
  const [ValidationProvince, setValidationProvince] = useState(false);
  const [ValidationDistrito, setValidationDistrito] = useState(false);

  async function infoUser() {
    let obj = {
      id: idStudent,
    };
    let res = await posthttps("infouser", obj);
    if (res.data.mother.direccion !== undefined) {
      setdepartament(res.data.mother.direccion.departament);
      setprovince(res.data.mother.direccion.province);
      setdepartamentoNro(res.data.mother.direccion.departamentoNro);
      setdistrito(res.data.mother.direccion.distrito);
      setreferencia(res.data.mother.direccion.referencia);
      setcelular2(res.data.mother.direccion.celular2);
      settelefono2(res.data.mother.direccion.telefono2);
      setcelular(res.data.mother.direccion.celular);
      settelefono(res.data.mother.direccion.telefono);
      setcode(res.data.mother.direccion.code);
      setcode2(res.data.mother.direccion.code2);
      setdireccion(res.data.mother.direccion.direccion);
    }

    setloading(false);
  }

  useEffect(() => {
    infoUser();
  }, []);

  async function saveDatos(values) {
    departament == null || departament == "Selecciona un departamento"
      ? setValidationDepartament(true)
      : setValidationDepartament(false);

    province == null || province == "Selecciona una provincia"
      ? setValidationProvince(true)
      : setValidationProvince(false);

    distrito == null || distrito == "Selecciona un Distrito"
      ? setValidationDistrito(true)
      : setValidationDistrito(false);

    let mother = {
      departament: departament,
      province: province,
      distrito: distrito,
      celular: values.celular,
      direccion: values.direccion,
      telefono: values.telefono,
      departamentoNro: departamentoNro,
      referencia: referencia,
      code: code,
      code2: code2,
      celular2: celular2,
      telefono2: telefono2,
    };
    let obj = {
      id: idStudent,
      datos: mother,
      relationship: "mother",
      dato: "direccion",
    };

    if (
      !(departament == null || departament == "Selecciona un departamento") &&
      !(province == null || province == "Selecciona una provincia") &&
      !(distrito == null || distrito == "Selecciona un Distrito")
    ) {
      setloading(true);
      await posthttps("datosgenerales", obj);
      props.handleNext();
      setloading(false);
    }
  }

  return loading ? (
    <Spinner />
  ) : (
    <form onSubmit={handleSubmit(saveDatos)} autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <SelectDepartment
            value={departament}
            onChange={(e) => {
              setdepartament(e.target.value);
              setValidationDepartament(false);
            }}
          />
          {ValidationDepartament && (
            <FormHelperText error>Departamento Obligatorio</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SelectProvincia
            onChange={(e) => {
              setprovince(e.target.value);
              setValidationProvince(false);
            }}
            value={province}
            departament={departament}
          />
          {ValidationProvince && (
            <FormHelperText error>Provincia obligatoria</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SelectDistrito
            onChange={(e) => {
              setdistrito(e.target.value);
              setValidationDistrito(false);
            }}
            value={distrito}
            province={province}
          />
          {ValidationDistrito && (
            <FormHelperText error>Distrito obligatorio</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Dirección"
            variant="outlined"
            fullWidth
            defaultValue={direccion}
            name="direccion"
            inputRef={register({
              required: "Dirección Obligatoria",
            })}
          />
          <FormHelperText error>
            {errors.direccion && errors.direccion.message}
          </FormHelperText>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Departamento Nro"
            variant="outlined"
            fullWidth
            defaultValue={departamentoNro}
            onChange={(e) => setdepartamentoNro(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Referencia"
            variant="outlined"
            fullWidth
            defaultValue={referencia}
            onChange={(e) => setreferencia(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            value="+51"
            variant="outlined"
            style={{ width: 50 }}
            disabled
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Celular 1"
            variant="outlined"
            fullWidth
            type="tel"
            inputProps={{ maxLength: 9 }}
            name="celular"
            defaultValue={celular}
            inputRef={register({
              required: "Celular Obligatorio",
            })}
            style={{ width: "calc((100%) - 50px)" }}
          />
          <FormHelperText error>
            {errors.celular && errors.celular.message}
          </FormHelperText>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            value="+51"
            variant="outlined"
            style={{ width: 50 }}
            disabled
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Celular 2"
            variant="outlined"
            fullWidth
            type="tel"
            inputProps={{ maxLength: 9 }}
            onChange={(e) => setcelular2(e.target.value)}
            value={celular2}
            style={{ width: "calc((100%) - 50px)" }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            value="+51"
            variant="outlined"
            style={{ width: 50 }}
            disabled
          />
          <SelectCodigos
            value={code}
            onChange={(e) => setcode(e.target.value)}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Teléfono fijo 1"
            variant="outlined"
            fullWidth
            type="tel"
            inputProps={{ maxLength: 7 }}
            defaultValue={telefono}
            name="telefono"
            style={{ width: "calc((100%) - 110px)" }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            value="+51"
            variant="outlined"
            style={{ width: 50 }}
            disabled
          />
          <SelectCodigos
            value={code2}
            onChange={(e) => setcode2(e.target.value)}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Teléfono fijo 2"
            variant="outlined"
            fullWidth
            type="tel"
            inputProps={{ maxLength: 7 }}
            onChange={(e) => settelefono2(e.target.value)}
            defaultValue={telefono2}
            style={{ width: "calc((100%) - 110px)" }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={() => props.handleBack()}
            variant="contained"
            color="primary"
          >
            Atras
          </Button>{" "}
          <Button type="submit" variant="contained" color="secondary">
            Siguiente
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Direccion;
