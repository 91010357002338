import React, { useState } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import SituacionLaboral from "./components/SituacionLaboral";
import DataGeneral from "./components/DataGeneral";
import DatosCompañia from "./components/DatosCompañia";
import Box from "@material-ui/core/Box";

function getSteps() {
  return ["Información General", "Situación Laboral", "Datos de Compañia"];
}

export default function DatosEmpleo(props) {
  const [status, setstatus] = useState();
  const [situacion, setsituacion] = useState();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const goToStep3 = (e, s) => {
    setActiveStep(2);
    setstatus(e);
    setsituacion(s);
  };
  const goToStep0 = () => {
    setActiveStep(0);
  };

  const gotoNext = (value) => {
    setstatus(value);
    handleNext();
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <DataGeneral
            gotoNext={gotoNext}
            goToStep3={goToStep3}
            activeStep={activeStep}
            handleBackItem={props.handleBack}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      case 1:
        return (
          <SituacionLaboral
            goToStep3={goToStep3}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      case 2:
        return (
          <DatosCompañia
            situacion={situacion}
            goToStep0={goToStep0}
            activeStep={activeStep}
            status={status}
            handleNextItem={props.handleNext}
            handleBack={handleBack}
          />
        );
    }
  }

  return (
    <Box pt={0} pl={5} pr={5} pb={0}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length ? (
        <Typography>All steps completed</Typography>
      ) : (
        <Typography>{getStepContent(activeStep)}</Typography>
      )}
    </Box>
  );
}
