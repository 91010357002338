//Dependencies
import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Page404 from "./views/Error404";
import AuthenticationService from "./auth/AuthenticationService";
import Home from "./views/Home";
import { ProtectedRoute } from "./components/Util/Ruta/ProtectedRoute";
import Landing from "./views/Landing/Landing";
import Login from "./views/Landing/Login";
import Register from "./views/Landing/Register";
import ForgotPassword from "./views/Landing/ForgotPassword";
import Admision from "./views/Admision/Admision";
import Inicio from "./views/Index/Inicio";
import ResetPassword from "./views/Landing/ResetPassword";

class Routes extends Component {
  onLogin() {
    // this.props.history.push("/")
  }
  render() {
    return (
      <Switch>
        {AuthenticationService.isUserAuthenticated() ? (
          <ProtectedRoute exact={true} exact path="/" component={Inicio} />
        ) : (
          <Route
            exact
            path="/:id"
            render={(props) => <Landing {...props} onLogin={this.onLogin} />}
          />
        )}
        <Route exact path="/" component={Home} />
        <Route exact path="/:id/login" component={Login} />
        <Route exact path="/:id/register" component={Register} />
        <Route exact path="/recovery/account" component={ForgotPassword} />
        <Route exact path="/auth/reset_password/:token" component={ResetPassword} />
        <ProtectedRoute
          exact={true}
          path="/admision/:idStudent"
          component={Admision}
        />
        <Route path="" component={Page404} />
      </Switch>
    );
  }
}

export default withRouter(Routes);
