import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

const SelectGrade = ({ value, onChange, level }) => {
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>Grado al que postula</InputLabel>
      <Select value={value} onChange={onChange} label="Grado al que postula">
        {level !== "INICIAL" && (
          <MenuItem value="1">
            1 {level === "INICIAL" ? "AÑOS" : "° GRADO"}
          </MenuItem>
        )}
        {level !== "INICIAL" && (
          <MenuItem value="2">
            2 {level === "INICIAL" ? "AÑOS" : "° GRADO"}
          </MenuItem>
        )}
        <MenuItem value="3">
          3 {level === "INICIAL" ? "AÑOS" : "° GRADO"}
        </MenuItem>
        <MenuItem value="4">
          4 {level === "INICIAL" ? "AÑOS" : "° GRADO"}
        </MenuItem>
        <MenuItem value="5">
          5 {level === "INICIAL" ? "AÑOS" : "° GRADO"}
        </MenuItem>
        {level !== "INICIAL" && level !== "SECUNDARIA" && (
          <MenuItem value="6">
            6 {level === "INICIAL" ? "AÑOS" : "° GRADO"}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default SelectGrade;
