import React, { useState, useEffect } from "react";
import SelectEstadoCivil from "../../../utils/SelectEstadoCivil";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import posthttps from "../../../hook/fetch/posthttps";
import Button from "@material-ui/core/Button";
import Spinner from "../../Spinner/Spinner";
import useForm from "react-hook-form";
import {
  TextField,
  Grid,
  FormHelperText,
  Box,
  Radio,
  RadioGroup,
  FormLabel,
  FormControlLabel,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import SelectTypeDocument from "../../../utils/SelectTypeDocument";
import moment from "moment";

const DatosGenerales = (props) => {
  const { idStudent } = useParams();
  const { handleSubmit, register, errors } = useForm();
  const [value, setvalue] = useState("SI");
  const [firstName, setfirstName] = useState("");
  const [secondSurname, setsecondSurname] = useState("");
  const [surname, setsurname] = useState("");
  const [secondName, setsecondName] = useState("");
  const [loading, setloading] = useState(true);
  const [typeDocument, settypeDocument] = useState("DNI");
  const [email1, setemail1] = useState("");
  const [email2, setemail2] = useState("");
  const [birthday, setbirthday] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );
  const [numberDocument, setnumberDocument] = useState("");
  const [civilState, setcivilState] = useState("soltero");

  async function infoUser() {
    let obj = {
      id: idStudent,
    };
    let res = await posthttps("infouser", obj);
    if (res.data.mother.datosGenerales !== undefined) {
      setfirstName(res.data.mother.datosGenerales.firstName);
      setsecondSurname(res.data.mother.datosGenerales.secondSurname);
      setsurname(res.data.mother.datosGenerales.surname);
      setsecondName(res.data.mother.datosGenerales.secondName);
      setvalue(res.data.mother.datosGenerales.alive);
      if (res.data.mother.datosGenerales.alive === "SI") {
        setemail1(res.data.mother.datosGenerales.email1);
        setemail2(res.data.mother.datosGenerales.email2);
        setbirthday(res.data.mother.datosGenerales.birthday);
        setnumberDocument(res.data.mother.datosGenerales.numberDocument);
        setcivilState(res.data.mother.datosGenerales.civilState);
        settypeDocument(res.data.mother.datosGenerales.typeDocument);
      }
    }
    setloading(false);
  }

  useEffect(() => {
    infoUser();
  }, []);

  async function saveDatos(values) {
    setloading(true);
    let mother = {
      firstName: values.name,
      secondSurname,
      surname: values.surname,
      secondName,
      alive: value,
      typeDocument,
      numberDocument: values.numberDocument,
      birthday,
      civilState,
      email1: values.email1,
      email2: values.email2,
    };
    let obj = {
      id: idStudent,
      datos: mother,
      relationship: "mother",
      dato: "datosGenerales",
    };
    await posthttps("datosgenerales", obj);
    if (value === "SI") {
      props.handleNext();
    } else {
      props.handleNextItem();
    }
    setloading(false);
  }
  function handler(id) {
    const elt = document.getElementById(`${id}`);
    elt.value = elt.value.toUpperCase();
  }

  return (
    <Box p={2}>
      {loading ? (
        <Spinner />
      ) : (
        <form onSubmit={handleSubmit(saveDatos)} autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Apellido paterno"
                fullWidth
                id="surname"
                onKeyUp={(e) => handler("surname")}
                defaultValue={surname}
                name="surname"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                inputRef={register({
                  required: "Apellido Obligatorio",
                })}
              />
              <FormHelperText error>
                {errors.surname && errors.surname.message}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Apellido materno"
                variant="outlined"
                fullWidth
                value={secondSurname}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setsecondSurname(e.target.value.toUpperCase())}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Nombre"
                variant="outlined"
                fullWidth
                id="firstName"
                onKeyUp={(e) => handler("firstName")}
                defaultValue={firstName}
                name="name"
                InputLabelProps={{ shrink: true }}
                inputRef={register({
                  required: "Nombre Obligatorio",
                })}
              />
              <FormHelperText error>
                {errors.name && errors.name.message}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Segundo nombre"
                variant="outlined"
                fullWidth
                value={secondName}
                onChange={(e) => setsecondName(e.target.value.toUpperCase())}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel component="legend">¿Vivo?</FormLabel>
              <RadioGroup
                onChange={(e) => setvalue(e.target.value)}
                value={value}
              >
                <FormControlLabel value="SI" control={<Radio />} label="SI" />
                <FormControlLabel value="NO" control={<Radio />} label="NO" />
              </RadioGroup>
            </Grid>
            {value === "SI" && (
              <>
                <Grid item xs={12} md={6}>
                  <SelectTypeDocument
                    value={typeDocument}
                    onChange={(e) => settypeDocument(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Número de documento"
                    variant="outlined"
                    fullWidth
                    defaultValue={numberDocument}
                    name="numberDocument"
                    InputLabelProps={{ shrink: true }}
                    inputRef={register({
                      required: "Número de documento obligatorio",
                      pattern: {
                        value: /^[0-9A-Z]{7,15}$/i,
                        message: "Número invalido",
                      },
                    })}
                  />
                  <FormHelperText error>
                    {errors.numberDocument && errors.numberDocument.message}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Fecha de nacimiento"
                    variant="outlined"
                    type="date"
                    fullWidth
                    defaultValue={birthday}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setbirthday(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SelectEstadoCivil
                    value={civilState}
                    onChange={(e) => setcivilState(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Correo electrónico"
                    variant="outlined"
                    fullWidth
                    name="email1"
                    defaultValue={email1}
                    InputLabelProps={{ shrink: true }}
                    inputRef={register({
                      required: "Email Obligatorio",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Email invalido",
                      },
                    })}
                  />
                  <FormHelperText error>
                    {errors.email1 && errors.email1.message}
                  </FormHelperText>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Button
                onClick={() => props.handleBackItem()}
                variant="contained"
                color="primary"
              >
                Atras
              </Button>{" "}
              <Button type="submit" variant="contained" color="secondary">
                Siguiente <NavigateNextIcon />
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Box>
  );
};

export default DatosGenerales;
