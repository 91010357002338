import React from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";

const SelectEstadoCivil = (props) => {
  const { value, onChange } = props;
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>Estado civil</InputLabel>
      <Select
        value={value}
        onChange={onChange}
        label="Estado civil"
      >
        <MenuItem value="soltero">SOLTERO</MenuItem>
        <MenuItem value="casado">CASADO</MenuItem>
        <MenuItem value="separado">SEPARADO</MenuItem>
        <MenuItem value="divorciado">DIVORCIADO</MenuItem>
        <MenuItem value="viudo">VIUDO</MenuItem>
      </Select>
    </FormControl>
  );
};

export default SelectEstadoCivil;
