import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import animation from "../../assets/json/class_animation.json";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core/";

const useStyles = makeStyles(() => ({
  root: {
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  animation: {
    maxWidth: 500,
    padding: 20,
  },
}));

const HomeOne = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Player
        loop
        src={animation}
        className={classes.animation}
        autoplay={true}
      />
      <Typography variant="h6" color="textSecondary">
        Formulario de Postulación
      </Typography>
      <Typography variant="H5">
        <b>NAPCLASS</b>
        <br />
        <br />
      </Typography>
      <Button
        href="https://www.netappperu.com/"
        target="_blank"
        color="secondary"
      >
        Netapp Perú
      </Button>
    </div>
  );
};

export default HomeOne;
