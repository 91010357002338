import React, { useState, useEffect } from "react";
import posthttps from "../../../hook/fetch/posthttps";
import userinfo from "../../../config/user";
import gethttps from "../../../hook/fetch/gethttps";
import SelectYears from "../../../utils/SelectYears";
import {
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormHelperText,
  Grid,
} from "@material-ui/core";
import Spinner from "../../Spinner/Spinner";
import { useParams } from "react-router-dom";

const DatosAdicionales = (props) => {
  const { idStudent } = useParams();
  const [value, setvalue] = useState("no");
  const [anio, setanio] = useState(null);
  const [loading, setLoading] = useState(true);
  const [schoolData, setschoolData] = useState();

  async function infoUser() {
    let obj = {
      id: idStudent,
    };
    let res = await posthttps("infouser", obj);
    if (res.data.mother.datosAdicionales !== undefined) {
      setvalue(res.data.mother.datosAdicionales.exAlumno);
      setanio(res.data.mother.datosAdicionales.anio);
    }
  }
  const getInfoSchool = async (id) => {
    setLoading(true);
    let res = await gethttps(`school/${id}`, "classroom");
    setschoolData(res.school);
    setLoading(false);
  };

  useEffect(() => {
    infoUser();
    getInfoSchool(userinfo.getSchool_id());
  }, []);

  async function saveDatos(values) {
    setLoading(true);
    let mother = {
      exAlumno: value,
      anio: anio,
    };
    let obj = {
      id: idStudent,
      datos: mother,
      relationship: "mother",
      dato: "datosAdicionales",
    };
    await posthttps("datosgenerales", obj);
    props.handleNext();
    setLoading(false);
  }

  return loading ? (
    <Spinner />
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormHelperText>
          ¿ERES EX ALUMNO DE {schoolData.tradeName}?
        </FormHelperText>
        <RadioGroup onChange={(e) => setvalue(e.target.value)} value={value}>
          <FormControlLabel
            value="si"
            control={<Radio color="primary" />}
            label="SI"
          />
          <FormControlLabel
            value="no"
            control={<Radio color="primary" />}
            label="NO"
          />
        </RadioGroup>
      </Grid>
      {value == "si" && (
        <Grid item xs={12}>
          <SelectYears value={anio} onChange={(e) => setanio(e.target.value)} />
        </Grid>
      )}
      <Grid item xs={12}>
        <Button
          onClick={() => props.handleBack()}
          variant="contained"
          color="primary"
        >
          Atras
        </Button>{" "}
        <Button
          variant="contained"
          onClick={() => saveDatos()}
          color="secondary"
        >
          Siguiente
        </Button>
      </Grid>
    </Grid>
  );
};

export default DatosAdicionales;
